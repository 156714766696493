<template>
  <div class="top" style="height: 100%;margin: 10px;background: #fff;">
    <!-- <div :class="fromFlg ? 'mark' : ''"></div> -->
    <div class="header">
      <div class="headerup">
        <div
          class="headerupleft"
          @click="
            () => {
              this.dialogVisible = true;
            }
          "
        >
          <svg class="icon" aria-hidden="true" style="margin-right: 10px">
            <use href="#icon-back"></use></svg
          >{{ $t("label.back_forms") }}
        </div>
        <div class="headerupcenter" ref="headleft">
          <input
            v-model="$store.state.creatform.channel"
            v-show="editLineFlg"
            maxlength="20"
          />
          <span>{{ editLineFlg ? "" : $store.state.creatform.channel }}</span>
          <svg class="img" aria-hidden="true" @click="editLine">
            <use href="#icon-editName"></use>
          </svg>
        </div>
        <div class="headerupright">
          <el-button
            @click="getformdata"
            size="mini"
            type="primary"
            v-show="activeName !== 'first'"
            v-loading="isSaveLoading"
            >{{ $t("component.telerecord.button.save") }}</el-button
          >
        </div>
      </div>
      <div class="headerdown" v-show="currentStep !== 1">
        <!-- <div class="headerdownleft">
        <el-select
          v-model="formpreview"
          placeholder="请选择"
          size="mini"
          style="width: 128px"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
        <div class="headerdowncenter">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane :label="$t('label.form_content')" name="first">
            </el-tab-pane>
            <el-tab-pane
              :label="$t('label_tabpage_optionsz')"
              name="second"
              :disabled="fromFlg"
              :style="fromFlg ? 'color: beige;' : ''"
            ></el-tab-pane>
            <el-tab-pane
              :label="$t('label.follow_up')"
              name="third"
              :disabled="fromFlg"
              :style="fromFlg ? 'color: beige;' : ''"
            ></el-tab-pane>
            <el-tab-pane
              :label="$t('label.tyle_preview')"
              name="fourth"
              :disabled="fromFlg"
            ></el-tab-pane>
          </el-tabs>
        </div>
        <div class="headerdownright" v-show="this.$store.state.creatform.itemDataId">
          <svg aria-hidden="true" @click="shareBtn">
              <use href="#icon-share-form"></use>
            </svg>
            <svg aria-hidden="true" @click="setupBtn">
              <use href="#icon-setup-form"></use>
            </svg>
          <!-- <button>Clone</button>
        <el-select
          v-model="actions"
          placeholder="请选择"
          size="mini"
          style="width: 100px"
        >
          <el-option
            v-for="item in actionsoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        </div>
      </div>
      <div style="width:100%;height:6px;background:#f0f0f0;"></div>
      <div class="nav">
        <!-- <select-template v-if="fromFlg"></select-template> -->
        <div class="stepBox" v-show="fromlg">
          <stepContent ref="stepCont" @complete="complete"></stepContent>
        </div>
        <createFollow v-show="followFlg" ref="followflg"></createFollow>
        <preview v-if="preview_" :thankContent="thankContent" :inputshow="inputshow"/>
        <optionform ref="option" v-show="opt"></optionform>
      </div>
    </div>
    <!-- create提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('label.back_forms')"
      :show-close="false"
      top="15%"
      width="40%"
      :close-on-click-modal="false"
    >
      <div class="createPage">
        <div
          style="
            font-size: 16px;
            font-weight: bold;
            word-break: break-word;
          "
        >
          <!-- 页面处于编辑状态，离开后，当前数据将不被保存 -->
          {{ $t("c469") }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="mini"
          @click="goList"
        >
          <!-- 返回表单列表 -->
          {{ $t("label.back_forms") }}
        </el-button>
        <el-button @click="dialogVisible=false" size="mini">
          {{ $t("label.emailtocloudcc.button.back") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 分享 -->
    <share ref="share"></share>
    <!-- 设置 -->
    <setup ref="setup"></setup>
  </div>
</template>
<script >
import share from "@/views/marketForm/components/share.vue";
import setup from "@/views/marketForm/components/setup.vue";
import createFollow from "@/views/marketForm/createFrom/followUp/index.vue";
import preview from "@/views/marketForm/createFrom/Previews/preview.vue";
import optionform from "@/views/marketForm/createFrom/Option/index.vue";
import stepContent from "@/views/marketForm/createFrom/fromType/stepDialog/index.vue";
import * as marketChannel from "./api";

export default {
  data() {
    return {
      dialogVisible: false,
      editLineFlg: false,
      headline: "",
      fromFlg: true,
      fromlg: true,
      followFlg: false,
      preview_: false,
      opt: false,
      activeName: "first",
      currentStep: 2,
      actions: "Actions",
      mydefault: " ",
      formpreview: "Learn more",
      options: [
        {
          value: "1",
          label: "Mirror",
        },
        {
          value: "2",
          label: "This is it",
        },
      ],
      actionsoptions: [
        {
          value: "1",
          label: "Mirror",
        },
      ],
      formdata: {}, //保存 编辑的参
      nodata: true,
      thankContent:'',
      inputshow:false,
      isSaveLoading:false,
    };
  },
  components: {
    optionform,
    preview,
    createFollow,
    stepContent,
    share,
    setup
  },
  created() {
    if (this.$route.query.createddata) {
      this.headline = this.$route.query.createddata;
    }
    if (this.$route.params.formname) {
      this.headline = this.$route.params.formname;
    }
    if (this.$route.params.tap) {
      this.activeName = "first";
      this.handleClick(this.$route.params.tap);
    }
  },
  mounted() {
    document.addEventListener("click", this.clickFn);
    if (this.$store.state.creatform.itemDataId) {
      this.fromFlg = false;
      this.activeName = "first";
      this.followFlg = false;
      this.preview_ = false;
      this.fromlg = true;
      this.opt = false;
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickFn);
  },
  methods: {
    clickFn(e) {
      // 记得在.select-box那边加上ref="selectBox"
      let headleft = this.$refs.headleft;
      // 重点来了：selectBox里是否包含点击的元素，不包含点击的元素就隐藏面板
      if (headleft) {
        if (!headleft.contains(e.target)) {
          this.editLineFlg = false;
        }
      }
    },
    shareBtn(){
      this.$refs.share.shareDialog = true
      this.$refs.share.init()
    },
    setupBtn(){
      this.$refs.setup.setupDialog = true
      this.$refs.setup.init()
    },
    complete() {
      this.activeName = "second";
      this.followFlg = false;
      this.preview_ = false;
      this.fromFlg = false;
      this.opt = true;
      this.fromlg = false;
    },
    editLine() {
      this.editLineFlg = !this.editLineFlg;
    },
    goList() {
      this.$store.state.creatform.itemDataId = "";
      this.$store.state.creatform.actionType == "new";
      this.$store.state.selectTempFlg = "";
      this.$store.state.creatform.emails = "";
      this.$router.push({
        path: "/marketForm/marketForm",
      });
    },
    handleCommand(command) {
      this.mydefault = command;
    },
    handleClick(tab) {
      if (tab.index === "3") {
        this.followFlg = false;
        this.preview_ = true;
        this.fromFlg = false;
        this.opt = false;
        this.fromlg = false;
        this.thankContent = this.$refs.option.text
        this.inputshow = this.$refs.option.inputshow
      } else if (tab.index === "2") {
        this.followFlg = true;
        this.preview_ = false;
        this.fromFlg = false;
        this.opt = false;
        this.fromlg = false;
      } else if (tab.index === "1") {
        this.followFlg = false;
        this.preview_ = false;
        this.fromFlg = false;
        this.opt = true;
        this.fromlg = false;
      } else if (tab.index === "0") {
        this.followFlg = false;
        this.preview_ = false;
        if (this.$store.state.creatform.itemDataId) {
          this.fromFlg = false;
        } else {
          this.fromFlg = true;
        }
        this.opt = false;
        this.fromlg = true;
      }
    },
    //获取隐藏字段
    getMember(params) {
      return new Promise(function (resolve, reject) {
        marketChannel
          .getFieldDefault(params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //获取注册页面
    getSource(params) {
      if(this.$store.state.creatform.objectid === '201200000108754gEHnj'){
        return new Promise(function (resolve, reject) {
          marketChannel
            .getFieldShow(params)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }else{
        let data = {
            id:params.id,
            objectid:this.$store.state.creatform.objectid,
            ownerobjectid:this.$store.state.creatform.ownerobjectid
          }
        return new Promise(function (resolve, reject) {
          marketChannel
            .getFormObjectField(data)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
    },
    //获取详细
    getDite(params) {
      return new Promise(function (resolve, reject) {
        marketChannel
          .getFormdetail(params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //编辑 保存
    getformdata() {
      if(this.isSaveLoading)return
      this.isSaveLoading=true
      let obj = {};
      if (this.$store.state.creatform.itemDataId) {
        let params = {
          id: this.$store.state.creatform.itemDataId,
        };
        Promise.all([
          this.getMember(params),
          this.getSource(params),
          this.getDite(params),
        ])
          .then((res) => {
            res[1].data.selectedFields.map((item)=>{
              item.required=item.required?item.required:"0"
            })
             this.isSaveLoading=false
            obj.leadDefaultValues = JSON.stringify(res[0].data.leaddvalue);
            obj.fields = JSON.stringify(res[1].data.selectedFields);
            obj.id = this.$store.state.creatform.itemDataId;
            obj.location = res[2].data.location;
            obj.subject = res[2].data.subject;
            obj.remark = res[2].data.remark;
            obj.bgimage = res[2].data.bgimage;
            obj.logo = res[2].data.logo;
            obj.modelType = res[2].data.modeltype;
            obj.campaignId = res[2].data.campaignid;
            obj.longitude = res[2].data.longitude;
            obj.latitude = res[2].data.latitude;
            obj.address = res[2].data.address;
            obj.channel = res[2].data.channel;
            obj.objectid = res[2].data.objectid;
            obj.ownerobjectid = res[2].data.ownerobjectid;
            this.$store.state.creatform.objectid = res[2].data.objectid;
            this.$store.state.creatform.ownerobjectid = res[2].data.ownerobjectid;
            if (this.$refs.option.radio == 1) {
              this.rtnType = "thanksmessage";
              obj.rtnType = "thanksmessage";
              obj.rtnUrl = this.$refs.option.text;
              obj.userEmailIds = JSON.stringify(this.$refs.option.value1);
            }
            if (this.$refs.option.radio == 2) {
              this.rtnType = "redirectpage";
              obj.rtnType = "redirectpage";
              obj.rtnUrl = this.$refs.option.anotherpage;
              obj.userEmailIds = JSON.stringify(this.$refs.option.value1);
            }
            obj.emails = this.$store.state.creatform.emails;
         
            this.formdata = obj;
            
            this.save();
          })
          .catch(() => {
             this.isSaveLoading=false
            return false;
          });
      }
    },

    //保存
    save() {
      if ((this.rtnType === "thanksmessage"&&this.formdata.rtnUrl&&this.formdata.rtnUrl!=='<span></span>')||this.rtnType !== "thanksmessage") {
        this.$refs.option.isError = false
        if((this.rtnType === "redirectpage"&&this.formdata.rtnUrl)||this.rtnType !== "redirectpage"){
          this.$refs.option.isUrlError = false
        marketChannel
          .getFormPageContent(this.formdata)
          .then((res) => {
            if (res.result) {
              this.formdata.pageContent = res.data.pageContent;
              marketChannel
                .saveForm(this.formdata)
                .then((res) => {
                  if (res.returnCode == "1") {
                    this.isSaveLoading = false
                    this.goList();
                  }
                })
                .catch(() => {
                  return false;
                });
            }
          })
          .catch(() => {
            return false;
          });
        this.$store.state.creatform.itemDataId = "";
        this.$store.state.creatform.actionType == "new";
        // this.$store.state.creatform.selectTempFlg = '';
        // this.$store.state.creatform.emails = "";
        }else{
          this.$message.error(this.$i18n.t("c150"))
          this.$refs.option.isUrlError = true
        }
      }else{
        this.$refs.option.isError = true
      }
    },
  },
};
</script>
<style scoped lang='scss'>
.icon {
  display: inline-block;
  cursor: pointer;
}
::v-deep .el-dialog__body {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 14px !important;
    font-weight: normal !important;
    font-family: MicrosoftYaHei;
    color: #080707;
    letter-spacing: 0;
  }
}
.mark {
  width: 100%;
  height: 77%;
  top: 23%;
  position: absolute;
  background: black;
  opacity: 0.5;
  z-index: 2000;
}
preview {
  margin-top: 0px;
}
::v-deep .el-input__inner {
  height: 30px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.header {
  width: 100%;
  height: 60px;
  background-color: azure;
  .headerup {
    height: 60px;
    background: #f5f5f5;
    border: 1px solid #dddbda;
    border-radius: 3px 3px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 16px 20px 0 20px;
    .headerupleft {
      color: #080707;
      letter-spacing: 0;
      margin-left: 15px;
      font-size:14px;
      cursor: pointer;
      img {
        width: 15.6px;
        height: 15.6px;
        margin-top: -3px;
        cursor: pointer;
      }
    }
    .headerupcenter {
      width: 60%;
      height: 60px;
      text-align: center;
      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #080707;
      letter-spacing: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        width: 15.1px;
        height: 15.2px;
        cursor: pointer;
        margin-left: 10px;
      }
      span {
        display: inline-block;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        white-space: nowrap;
      }
      input {
        width: 90%;
      }
    }
    .headerupright {
      // width: 10%;
      width: 65px;
// height: 42px;

      text-align: right;
      font-size: 12px;
      color: #080707;
      letter-spacing: 0;
      margin-right: 15px;
      button{
        font-size: 12px;
        background: #2D6CFC;
        width: 65px;
height: 30px;
      }
    }
  }
  .headerdown {
    position:relative;
    height: 62px;
background: #FDFDFF;
    border: 1px solid #dedcda;
    border-top: none;
    border-radius: 0 0 3px 3px;
    display: flex;
    justify-content: center;
    
    .headerdowncenter {
::v-deep .el-tabs__active-bar{
        height:3px;
    }
      
      ::v-deep .el-tabs__nav-scroll {
        margin-top: 4px;
        .el-tabs__nav-wrap::after{
          background-color:#fff;
        }
      }
      ::v-deep .el-tabs__item{
        line-height: 59px;
    height: 59px;
        font-size: 16px;
        font-weight: bold;
        color: #BEBEBE;
      }
      ::v-deep .is-top {
        font-size: 16px;
        font-weight: bold;
color: #BEBEBE;
        letter-spacing: 0;
      }
      ::v-deep .is-active {
        font-family: MicrosoftYaHei-Bold;
        font-weight: bold;
        font-size: 16px;
        color: #080707;
        letter-spacing: 0;
      }
      ::v-deep .el-tabs__active-bar {
        background-color: #2D6CFC;
      }
    }
    .headerdownright {
      position:absolute;
      top:22px;
      right:129px;
      svg:first-child{
        margin-right:20px;
      }
      svg{
        width:20px;
        height:20px;
        cursor: pointer;
      }
    }
  }
}
.stepBox {
  position: sticky;
  margin: 0 2px;
  background-color: #ffffff;
}
.createPage{
  height: 150px;
  display: flex;
  align-items: center;
}
</style>