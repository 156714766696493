/*
 * @Author: your name
 * @Date: 2022-03-11 20:01:18
 * @LastEditTime: 2022-03-25 16:15:36
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\marketForm\createFrom\api.js
 */
import axios from '@/config/httpConfig'
// 获取form的潜在客户的字段设置
export function getFieldDefault(data) {
    return axios.post('/form/getFieldDefault', data)
}
// 获取form的市场活动成员的字段设置
export function getFieldShow(data) {
    return axios.post('/form/getFieldShow', data)
}
//获取form详细信息
export function getFormdetail(data) {
    return axios.post('/form/getFormDetail', data)
}
//生成表单注册页面（获取保存Form的pageContent）
export function getFormPageContent(data) {
    return axios.post('/form/getFormPageContent', data)
}
//保存form信息
export function saveForm(data) {
    return axios.post('/form/saveForm', data)
}
// 获取表单对应对象的字段
export function getFormObjectField(data) {
    return axios.post('/form/getFormObjectField', data)
}