<!--设置页 -->
<template>
  <div class="setup_box">
    <el-dialog
      :title="$t('label.emailsync.form.seversetting')"
      :visible.sync="setupDialog"
      width="800px"
      custom-class="dialog"
      :close-on-click-modal="false"
    >
      <el-form
        class="sharecontent"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="205px"
      >
        <!-- 提交限制 -->
        <p>{{ $t("c193") }}</p>
        <div class="num_box">
          <!-- 限制提交数量 -->
          <span class="num_label">{{ $t("c194") }}</span>
          <!-- 是 否 -->
          <el-radio v-model="islimitnum" label="1">{{
            $t("label.tabpage.yesz")
          }}</el-radio>
          <el-radio v-model="islimitnum" label="2">{{
            $t("label.sso.isActiveNo")
          }}</el-radio>
        </div>
        <!-- 最大提交限制 -->
        <el-form-item :label="$t('c195')" prop="maximum" v-if="islimitnum==='1'">
          <el-input-number
            v-model="setupForm.maximum"
            @change="handleChange"
            :min="0"
            :controls="false"
            :precision="0"
          ></el-input-number>
        </el-form-item>
        <!-- 达到限制消息 -->
        <el-form-item :label="$t('c196')" prop="limitmessage" v-if="islimitnum==='1'">
          <el-input
            v-model="setupForm.limitmessage"
            style="width: 200px"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="setupSave">
          {{ $t("label.chatter.save") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import * as marketChannel from "../api.js"; //api

export default {
  data() {
    return {
      setupDialog: false,
      islimitnum: "1",
      setupForm: {
        maximum: "1",
        limitmessage: '',
      },
    };
  },
  mounted() {},
  methods: {
    init() {
      marketChannel
        .getFormSetting({ id: this.$store.state.creatform.itemDataId })
        .then((res) => {
          
          this.setupForm.limitmessage = res.data[0].limitmessage?res.data[0].limitmessage:this.$i18n.t('c197');
          this.setupForm.maximum = res.data[0].maximum ? res.data[0].maximum : "1";
          this.islimitnum = res.data[0].islimitnum?res.data[0].islimitnum:'1';
        })
        .catch(() => {});
    },
    handleChange() {},
    setupSave() {
      let data = {
        id: this.$store.state.creatform.itemDataId,
        maximum: this.setupForm.maximum,
        limitmessage: this.setupForm.limitmessage,
        islimitnum: this.islimitnum,
      };

      marketChannel
        .saveFormSetting(data)
        .then((res) => {
          if (res.result) {
            this.$message({
              showClose: true,
              type: "success",
              //   设置成功
              message: this.$i18n.t("label_set_successfully"),
            });
            this.setupDialog = false;
          } else {
            this.$message({
              showClose: true,
              type: "error",
              //   设置失败
              message: this.$i18n.t("label_setup_failed"),
            });
            this.setupDialog = false;
          }
          
          // c197
          // 您的提交次数已达到上限，感谢您的参与！
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang='scss' scoped>
.setup_box {
  ::v-deep .el-dialog__header {
    span {
      color: #fff;
      font-size: 16px;
      line-height: 28px;
      padding-left: 20px;
    }
    i {
      color: #fff;
      font-size: 26px;
      padding: 0 20px 0 0;
    }
    height: 65px;
    background: linear-gradient(90deg, #2d6cfc 0%, #6c98fd 100%);
  }
  ::v-deep .el-dialog__body {
    padding: 0 !important;
    .sharecontent {
      width: 800px;
      padding: 20px 40px;
      box-sizing: border-box;
      .num_box {
        margin-bottom: 15px;
        margin-top: 15px;
        .num_label {
          color: #333333;
          font-size: 14px;
          margin-top: 10px;
          margin-right: 12px;
          width:193px;
          text-align: right;
          display: inline-block;
        }
        .el-radio__input,
        .el-radio__inner {
          top: 0 !important;
          margin-top: 0 !important;
        }
      }
      .el-input-number .el-input__inner {
        text-align: left;
        width:200px;
      }
      .el-form-item__label {
        line-height: 34px !important;
      }
      .el-form-item {
        margin-bottom: 5px;
      }
      p:first-child {
        font-weight: bold;
        color: #333333;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }
  .dialog-footer {
    .el-button {
      margin-right: 20px;
      background: #2d6cfc;
    }
  }
}
</style>
