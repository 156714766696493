<template>
  <div class="viewButtons">
    <div class="shuttle">
      <oneShuttle
        :key="num"
        ref="oneShuttle"
        :viewUnselectedFieldList="stepThrNoSelectList"
        :viewSelectedFieldArr="stepThrSelectedList"
        :title="$t('label.chosen.field')"
        @memberfun="memberfun"
      />
    </div>
    <!-- </el-form-item>
      </el-col>
    </el-form> -->
    <!-- 右侧表单预览 -->
    <div class="selectImg">
      <div class="baglog">
        <div class="smallrema">
          <div v-if="baidudataD.logo" class="smallog">
            <img :src="imgSrc(baidudataD.logo)" alt="" />
          </div>
          <div
            v-if="
              baidudataD.bgimage &&
              $store.state.creatform.selectTempFlg == 'generalMode'
            "
            class="samllgeneralMode"
          >
            <img :src="imgSrc(baidudataD.bgimage)" alt="" />
          </div>
          <h1 v-if="baidudataD.subject">
            {{ baidudataD.subject }}
          </h1>
          <el-input
            type="textarea"
            class="smallp"
            v-if="baidudataD.remark"
            :autosize="{ minRows: 0, maxRows: 999 }"
            v-model="baidudataD.remark"
            readonly
          ></el-input>
          <div
            v-if="baidudataD.subject && stepThrSelectedList.length < 1"
            class="ziduan"
          >
            <div v-for="sel in arr" :key="sel.fieldname" class="selected">
              <p>
                {{ sel.label }}
                <span v-if="sel.required === '1'">*</span>
              </p>
              <input type="text" disabled />
            </div>
          </div>
          <div
            v-if="baidudataD.subject && stepThrData.length > 0"
            class="selectlists"
          >
            <div
              v-for="(selectVal, index) in stepThrData"
              :key="index"
              class="selected"
            >
              <p>
                {{ selectVal.label
                }}<span v-show="selectVal.isRequier" style="color: red">*</span>
              </p>
              <input type="text" disabled />
            </div>
          </div>
          <div
            class="baiduapp"
            v-if="
              $store.state.creatform.selectTempFlg == 'positioningModel' &&
              baidudataD.location
            "
          >
            <baidu-map
              class="baiduview"
              :center="baidudataD.center"
              zoom="19"
              :scroll-wheel-zoom="true"
            >
              <bm-marker :position="baidudataD.center"> </bm-marker>
            </baidu-map>
            <p v-if="baidudataD.actlocat">
              {{ $t("AD") }}：{{ baidudataD.actlocat }}
            </p>
          </div>
          <div class="baidusub">
            <button v-if="baidudataD.subject">{{ $t("label.submit") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as marketChannel from "../../api"; //api
import oneShuttle from "@/views/marketForm/components/shuttleBox/oneShuttle.vue";
import Vue from "vue";
import BaiduMap from "vue-baidu-map"; //引入百度地图组件库
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "6XPhZVfaUKeRAU9Q4X9zRwtiNGouGdzl",
});
export default {
  name: "stepThr",
  components: {
    oneShuttle,
  },
  props: {
    baidudata: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      baidudataD: this.baidudata,
      arr: [
        {
          label: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
        {
          label: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
        {
          label: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
      ],
      formLabelWidth: "118px",
      stepThreeFormData: {},
      num: 1, // 父组件给子组件动态传值，子组件获取不到所以需要加一个开关
      allFields: [], // 所有字段
      selectedFields: [], // 已选id
      //已选字段列表
      stepThrSelectedList: [
        // {
        //     id:'name',
        //     labelName:'姓名',
        //     label:'姓名',
        //     isselect:true
        // },
      ],
      //未选字段列表
      stepThrNoSelectList: [
        // {
        //     id:'sex',
        //     labelName:'性别',
        //     label:'性别',
        //     isselect:false
        // },
        // {
        //     id:'phone',
        //     labelName:'手机',
        //     label:'手机',
        //     isselect:false
        // },
        // {
        //     id:'wechat',
        //     labelName:'微信',
        //     label:'微信',
        //     isselect:false
        // },
        // {
        //     id:'email',
        //     labelName:'邮件',
        //     label:'邮件',
        //     isselect:false
        // },{
        //     id:'industry',
        //     labelName:'行业',
        //     label:'行业',
        //     isselect:false
        // },{
        //     id:'company',
        //     labelName:'公司',
        //     label:'公司',
        //     isselect:false
        // },
        // {
        //     id:'post',
        //     labelName:'职位',
        //     label:'职位',
        //     isselect:false
        // }
      ],
      stepFourRequiredIds: [], // 步骤四的已选ids
      stepFourNoSelectList: [], // 步骤四的备选
      stepFourSelectedList: [], // 步骤四的已选
      token: this.$CCDK.CCToken.getToken(),
      stepThrData: [],
    };
  },
  watch: {},
  created() {
    this.getStepThreeData();
  },
  mounted() {},
  methods: {
    memberfun(val) {
      this.stepThrData = val;
    },
    //获取图片
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
    // 获取数据
    getStepThreeData() {
      let params;

      if (this.$store.state.creatform.objectid === "201200000108754gEHnj") {
        if (this.$store.state.creatform.actionType == "edit") {
          params = { id: this.$store.state.creatform.itemDataId };
        } else {
          // 新建
          params = { id: "" };
        }
        marketChannel
          .getFieldShow(params)
          .then((res) => {
            if (res.result) {
              this.num++; // 为了给子组件动态传参可以生效
              this.allFields = res.data.allFields; // 所有字段
              this.selectedFields = res.data.selectedFields; // 已选字段id
              this.stepThrSelectedList = [];
              this.stepThrNoSelectList = [];
              // // 循环所有数据，将已选和未选从所有数据中拿出来
              // 步骤三的备选
              this.stepThrNoSelectList = [...this.allFields].filter((x) =>
                [...this.selectedFields].every(
                  (y) => y.fieldname !== x.fieldname
                )
              ); // 取差集
              // 步骤三的已选
              // this.stepThrSelectedList = [...this.allFields].filter((x) =>
              //   [...this.selectedFields].some((y) => y.fieldname === x.fieldname)
              // ) // 取并集
              // for (let i = 0; i < res.data.selectedFields.length; i++) {
              //   for (let j = 0; j < res.data.allFields.length; j++) {
              //     if (
              //       res.data.allFields[j].fieldname ==
              //       res.data.selectedFields[i].fieldname(res.data.allFields[j].objectid == this.$store.state.creatform.objectid||
              //         res.data.allFields[j].objectid == this.$store.state.creatform.ownerobjectid)
              //     ) {
              //       this.stepThrSelectedList.push({
              //       fieldname:res.data.allFields[j].fieldname,
              //       label:res.data.allFields[j].label,
              //       required:res.data.selectedFields[i].required,
              //       fieldname:res.data.allFields[j].fieldname,
              //       fieldtype:res.data.allFields[j].fieldtype,
              //       id:res.data.allFields[j].id,
              //       defaultRequired:res.data.selectedFields[i].required==='1'?true:false,
              //       objectid: res.data.allFields[j].objectid,
              //       ownerobjectid: res.data.allFields[j].ownerobjectid
              //       })
              //     }
              //   }
              // }
              res.data.selectedFields.forEach((val) => {
                if (
                  val.required === "1" &&
                  (val.objectid === "lead" ||
                    val.objectid === "contact" ||
                    val.objectid === "201200000108754gEHnj") &&
                  val.fieldname === "email"
                ) {
                  this.$set(val, "defaultRequired", true);
                } else {
                  this.$set(val, "defaultRequired", false);
                }
              });

              this.stepThrSelectedList = res.data.selectedFields;
              // 步骤四的必选Ids
              this.stepFourRequiredIds = [];
              // 取出required作为步骤4的必选
              this.stepFourRequiredIds = this.selectedFields.filter((item) => {
                return item.required == 1;
              });
              //
              //
              //
              //
              //
              //
            }
          })
          .catch(() => {
            return false;
          });
      } else {
        if (this.$store.state.creatform.itemDataId) {
          params = {
            id: this.$store.state.creatform.itemDataId,
            objectid: this.$store.state.creatform.objectid,
            ownerobjectid: this.$store.state.creatform.ownerobjectid,
          };
          this.getFormObjectField(params);
        } else {
          // 新建
          params = {
            id: "",
            objectid: this.$store.state.creatform.objectid,
            ownerobjectid: this.$store.state.creatform.ownerobjectid,
          };
          this.getFormObjectField(params);
        }
      }
    },
    getFormObjectField(params) {
      marketChannel
        .getFormObjectField(params)
        .then((res) => {
          if (res.result) {
            this.num++; // 为了给子组件动态传参可以生效
            this.allFields = res.data.allFields; // 所有字段
            this.selectedFields = res.data.selectedFields; // 已选字段id
            this.stepThrSelectedList = [];
            this.stepThrNoSelectList = [];
            // // 循环所有数据，将已选和未选从所有数据中拿出来
            // 步骤三的备选
            if (res.data.selectedFields) {
              this.stepThrNoSelectList = [...this.allFields].filter((x) =>
                [...this.selectedFields].every(
                  (y) => y.fieldname !== x.fieldname
                )
              ); // 取差集
            } else {
              this.stepThrNoSelectList = this.allFields;
            }
            // for (let i = 0; i < res.data.selectedFields.length; i++) {
            //   for (let j = 0; j < res.data.allFields.length; j++) {
            //     if (
            //       res.data.allFields[j].fieldname ==
            //       res.data.selectedFields[i].fieldname
            //       &&(res.data.allFields[j].objectid == this.$store.state.creatform.objectid||
            //       res.data.allFields[j].objectid == this.$store.state.creatform.ownerobjectid)
            //     ) {
            //       this.stepThrSelectedList.push({
            //       label:res.data.allFields[j].label,
            //       required:res.data.selectedFields[i].required,
            //       fieldname:res.data.allFields[j].fieldname,
            //       fieldtype:res.data.allFields[j].fieldtype,
            //       id:res.data.allFields[j].id,
            //       defaultRequired:res.data.selectedFields[i].required==='1'?true:false,
            //       objectid: res.data.allFields[j].objectid,
            //       ownerobjectid: res.data.allFields[j].ownerobjectid
            //       })
            //     }
            //   }
            // }
            res.data.selectedFields.forEach((val) => {
              if (
                val.required === "1" &&
                (val.objectid === "lead" ||
                  val.objectid === "contact" ||
                  val.objectid === "201200000108754gEHnj") &&
                val.fieldname === "email"
              ) {
                this.$set(val, "defaultRequired", true);
              } else {
                this.$set(val, "defaultRequired", false);
              }
            });

            this.stepThrSelectedList = res.data.selectedFields;
            //
            // 步骤四的必选Ids
            this.stepFourRequiredIds = [];
            // 取出required作为步骤4的必选
            this.stepFourRequiredIds = this.selectedFields.filter((item) => {
              return item.required == 1;
            });
          }
        })
        .catch(() => {
          return false;
        });
    },

    // 根据步骤三的数据进行处理，过滤步骤四的初始字段
    getStepFourNoSelectInitList() {
      // 步骤4的备选列表
      this.$refs.oneShuttle.curGroupLists();
      let stepFourAllFieldList = []; // 步骤四的所有列表
      let stepFourNoSelectList = []; // 步骤四的备选列表
      stepFourAllFieldList = this.$refs.oneShuttle.viewSelectedFieldList;
      // 从所有列表中过滤出备选
      stepFourNoSelectList = [...stepFourAllFieldList].filter((x) =>
        [...this.stepFourRequiredIds].every((y) => y.fieldname !== x.fieldname)
      );
      return stepFourNoSelectList;
    },
    getStepFourSelectedInitList() {
      // 步骤4的已选列表
      this.$refs.oneShuttle.curGroupLists();
      let stepFourAllFieldList = []; // 步骤四的所有列表
      // let stepFourSelectedList = []; // 步骤四的已选列表
      stepFourAllFieldList = this.$refs.oneShuttle.viewSelectedFieldList;

      // // 从所有列表中过滤出已选
      // stepFourSelectedList = [...stepFourAllFieldList].filter((x) =>
      //   [...this.stepFourRequiredIds].some((y) => y.fieldname === x.fieldname)
      // );
      return stepFourAllFieldList;
      // return stepFourSelectedList;
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .buttonGroup {
//   display: block;
//   float: left;
//   padding: 0px 10px;
//   display: flex;
// }
// .grid-content {
//   border-radius: 4px;
//   min-height: 36px;
// }
// ::v-deep .row {
//   display: flex;
//   width: 100%;
//   justify-content: center;
//   align-items: center;
// }
// .groupTitle {
//   // height: 40px;
//   line-height: 40px;
//   font-size: 16px;
//   padding-left: 20px;
//   margin-bottom: 1em;
//   background: #f0f0f0;
// }
::v-deep .selectImg .baglog .smallrema .selected input {
  width: 96% !important;
}
.viewButtons {
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.selectImg {
  width: 300px;
  min-height: 400px;
  background: #f4f8fa;
  .baglog {
    width: 80%;
    margin-left: 10%;
    margin-top: 25px;
    margin-bottom: 25px;
    background: #fff;
    overflow: hidden;
    .smallrema {
      width: 90%;
      margin-left: 5%;
      height: 400px;
      margin-top: 10px;
      margin-bottom: 10px;
      overflow: auto;
      .smallog {
        width: 100%;
        height: 40px;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .samllgeneralMode {
        width: 100%;
        margin-top: 5px;
        background: palegoldenrod;
        img {
          width: 100%;
          margin-bottom: 5px;
          margin-top: 5px;
          object-fit: contain;
        }
      }
      h1 {
        width: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        white-space: nowrap;
      }
      .smallp {
        text-align: center;
        width: 100%;
        font-size: 12px;
        ::v-deep .el-textarea__inner {
          border: none;
          resize: none;
          padding: 0;
        }

        // transform: scale(0.7);
        zoom: 0.7;
      }
      .ziduan {
        width: 100%;
        height: 150px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .selected {
        width: 100%;
        margin-top: 10px;
        p {
          margin: 0;
          font-size: 12px;
          zoom: 0.8;
        }
        input {
          width: 100%;
          outline: none;
          border: 1px solid #cbd6e2;
          height: 20px;
          background: #f5f8fa;
        }
      }
      .baiduapp {
        width: 100%;
        .baiduview {
          margin: 10px auto;
          width: 100%;
          height: 100px;
        }
        p {
          zoom: 0.7;
        }
      }
      .baidusub {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        button {
          border: none;
          outline: none;
          color: #fff;
          background: #2d6cfc;
          border-radius: 3px;
          margin-bottom: 10px;
          padding: 2px 5px;
          zoom: 0.8;
        }
      }
    }
  }
}
.smallrema::-webkit-scrollbar {
  width: 0px;
}
.shuttle {
  width: 400px;
}
</style>
