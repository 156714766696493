var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewButtons"},[_c('div',{staticClass:"leftvalue"},[_c('el-form',{ref:"objForms",attrs:{"model":_vm.stepTwoFormData,"label-width":_vm.formLabelWidth,"label-position":"left","size":"small"}},[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"screen_s",attrs:{"span":24}},[_c('el-form-item',[_c('div',{staticClass:"select_one fieldTextStyle",staticStyle:{"margin-left":"28px"}},[_vm._v(" "+_vm._s(_vm.$t("setup.layout.label.selectedfield"))+" ")]),_c('p',{staticClass:"equalP"}),_c('div',{staticClass:"select_two fieldTextStyle"},[_c('div',{staticStyle:{"margin-left":"180px"}},[_vm._v(" "+_vm._s(_vm.$t("label.tabpage.value"))+" ")])]),_c('div',{staticStyle:{"width":"100px"}})])],1)]),_c('el-col',{attrs:{"span":24}},_vm._l((_vm.stepTwoFormData.leadDefaultValues),function(ev,index){return _c('div',{key:index,staticClass:"screen_s",attrs:{"span":24}},[_c('el-form-item',[_c('span',[_vm._v(_vm._s(index + 1)+"、")]),_c('el-select',{staticClass:"select_one sel_style",attrs:{"placeholder":_vm.$t('label.selectfield'),"filterable":"","clearable":""},on:{"change":function($event){return _vm.selectChange($event, index, ev)},"focus":function($event){return _vm.selectFocus(index)},"clear":function($event){return _vm.clearField(ev)}},model:{value:(ev.fieldid),callback:function ($$v) {_vm.$set(ev, "fieldid", $$v)},expression:"ev.fieldid"}},_vm._l((_vm.selectFieldList),function(item){return _c('el-option',{key:item.datafieldRef,attrs:{"label":item.labelName,"value":item.datafieldRef},nativeOn:{"click":function($event){return _vm.optionChange(item, index)}}})}),1),_c('p',{staticClass:"equalP"},[_vm._v(_vm._s(_vm.$t("label.tabpage.equalz")))]),_c('div',{staticClass:"select_two sel_style_box"},[(
                  ev.schemefieldType !== 'L' &&
                  ev.schemefieldType !== 'ct' &&
                  ev.schemefieldType !== 'F' &&
                  ev.schemefieldType !== 'D'
                )?_c('el-input',{attrs:{"placeholder":_vm.$t('label.please.fill.out')},model:{value:(ev.dvalue),callback:function ($$v) {_vm.$set(ev, "dvalue", $$v)},expression:"ev.dvalue"}}):(ev.schemefieldType == 'L')?_c('el-select',{staticClass:"select_one sel_style",attrs:{"placeholder":_vm.$t('label.selectfield'),"filterable":"","clearable":""},on:{"focus":function($event){return _vm.selectListAll()}},model:{value:(ev.dvalue),callback:function ($$v) {_vm.$set(ev, "dvalue", $$v)},expression:"ev.dvalue"}},_vm._l((_vm.selectList),function(item){return _c('el-option',{key:item.codekey,attrs:{"label":item.codekey,"value":item.codevalue},nativeOn:{"click":function($event){return _vm.optionselect(item, index)}}})}),1):(ev.schemefieldType == 'ct')?_c('el-select',{staticClass:"select_one sel_style",attrs:{"placeholder":_vm.$t('label.selectfield'),"filterable":"","clearable":""},on:{"focus":function($event){return _vm.selectListAll(ev.schemefieldType)}},model:{value:(ev.dvalue),callback:function ($$v) {_vm.$set(ev, "dvalue", $$v)},expression:"ev.dvalue"}},_vm._l((_vm.currentList),function(item){return _c('el-option',{key:item.currencyname,attrs:{"label":item.currencyname,"value":item.currencycode},nativeOn:{"click":function($event){return _vm.optionselect(item, index)}}})}),1):(
                  ev.schemefieldType === 'F' || ev.schemefieldType === 'D'
                )?_c('el-date-picker',{attrs:{"type":"date","placeholder":"选择日期"},model:{value:(ev.dvalue),callback:function ($$v) {_vm.$set(ev, "dvalue", $$v)},expression:"ev.dvalue"}}):_vm._e()],1),_c('div',{staticStyle:{"width":"100px"}},[(_vm.stepTwoFormData.leadDefaultValues.length !== 1)?_c('span',{staticClass:"btn_s",on:{"click":function($event){return _vm.dels(index)}}},[_vm._v("-")]):_vm._e(),(_vm.stepTwoFormData.leadDefaultValues.length - 1 === index)?_c('span',{staticClass:"btn_s",on:{"click":_vm.adds}},[_vm._v("+")]):_vm._e()])],1)],1)}),0)],1)],1),_c('div',{staticClass:"selectImg"},[_c('div',{staticClass:"baglog"},[_c('div',{staticClass:"smallrema"},[(_vm.baidudataD.logo)?_c('div',{staticClass:"smallog"},[_c('img',{attrs:{"src":_vm.imgSrc(_vm.baidudataD.logo),"alt":""}})]):_vm._e(),(
            _vm.baidudataD.bgimage &&
            _vm.$store.state.creatform.selectTempFlg == 'generalMode'
          )?_c('div',{staticClass:"samllgeneralMode"},[_c('img',{attrs:{"src":_vm.imgSrc(_vm.baidudataD.bgimage),"alt":""}})]):_vm._e(),(_vm.baidudataD.subject)?_c('h1',[_vm._v(" "+_vm._s(_vm.baidudataD.subject)+" ")]):_vm._e(),(_vm.baidudataD.remark)?_c('el-input',{staticClass:"smallp",attrs:{"type":"textarea","autosize":{ minRows: 0 },"readonly":""},model:{value:(_vm.baidudataD.remark),callback:function ($$v) {_vm.$set(_vm.baidudataD, "remark", $$v)},expression:"baidudataD.remark"}}):_vm._e(),(_vm.baidudataD.subject && _vm.viewSelectedFieldList.length < 1)?_c('div',{staticClass:"ziduan"},_vm._l((_vm.arr),function(sel){return _c('div',{key:sel.fieldname,staticClass:"selected"},[_c('p',[_vm._v(" "+_vm._s(sel.label)+" "),(sel.required === '1')?_c('span',[_vm._v("*")]):_vm._e()]),_c('input',{attrs:{"type":"text","disabled":""}})])}),0):_vm._e(),(_vm.baidudataD.subject && _vm.viewSelectedFieldList.length > 0)?_c('div',{staticClass:"selectlists"},_vm._l((_vm.viewSelectedFieldList),function(sel){return _c('div',{key:sel.label,staticClass:"selected"},[_c('p',[_vm._v(_vm._s(sel.label)),(sel.required == 1)?_c('span',[_vm._v("*")]):_vm._e()]),_c('input',{attrs:{"type":"text","disabled":""}})])}),0):_vm._e(),(
            _vm.$store.state.creatform.selectTempFlg == 'positioningModel' &&
            _vm.baidudataD.location
          )?_c('div',{staticClass:"baiduapp"},[(_vm.baidushow)?_c('baidu-map',{staticClass:"baiduview",attrs:{"center":_vm.baidudataD.center,"zoom":"19","scroll-wheel-zoom":true}},[_c('bm-marker',{attrs:{"position":_vm.baidudataD.center}})],1):_vm._e(),(_vm.baidudataD.actlocat)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("AD"))+"："+_vm._s(_vm.baidudataD.actlocat)+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"baidusub"},[(_vm.baidudataD.subject)?_c('button',[_vm._v(_vm._s(_vm.$t("label.submit")))]):_vm._e()])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }