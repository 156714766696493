<!-- 穿梭框 -->
<template>
  <div id="row">
    <div class="pull-left dragBox">
      <!-- 可选字段 -->
      <span class="font_style">{{ $t("label.select.field") }}</span>
      <div class="shuttle">
        <div style="position: relative; height: 30px">
          <i
            class="iconfont icon-search-fill"
            style="left: 10px; position: absolute; top: 5px"
          ></i>
          <!-- 搜索字段 -->
          <el-input
            :placeholder="$t('label.searchfield')"
            class="left_inp"
            style="position: absolute; left: 35px; top: 0"
            v-model="searchVal"
            @input="querySearchAsync"
          ></el-input>
        </div>

        <draggable
          class="list-group ms-column itemBox shu_left"
          v-model="originFields"
          group="tasks"
          animation="300"
          @end="onEndLeft"
        >
          <span
            class="dragItem"
            v-for="(element, index) in originFields"
            :key="index"
            @click="allLeftSelect(element, index)"
            :class="[element.required == '1' ? 'Selectes' : '']"
            :id="element.id"
            >{{ element.label }}</span
          >
        </draggable>
      </div>
    </div>
    <el-button-group class="buttonGroup">
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('vue_label_normal_cancel')"
        placement="top-start"
      >
        <el-button
          type="primary"
          class="lefts"
          @click="allRight.length > 0 ? allRightClick() : ''"
        >
          <i class="el-icon-arrow-left"></i>
        </el-button>
      </el-tooltip>
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('vue_label_normal_select')"
        placement="top-start"
      >
        <el-button
          type="primary"
          @click="allLeft.length > 0 ? allLeftClick() : ''"
          style="margin-right: 0px"
          class="rights"
        >
          <i class="el-icon-arrow-right"></i>
        </el-button>
      </el-tooltip>
    </el-button-group>

    <!-- 已选字段 -->
    <div class="pull-left dragBox" id="background">
      <span class="font_style">{{ title }}</span>
      <div class="right_box">
        <div class="tit_center">
          <!-- 字段名称 是否必填-->
          <span>{{ $t("label.mr.title.fieldName") }}</span>
          <span>{{ $t("c321") }}</span>
        </div>
        <draggable
          class="list-group ms-column itemBox shu_right"
          v-model="viewSelectedFieldList"
          group="tasks"
          sort="true"
          animation="300"
          @end="onEndRight"
          :move="onMove"
        >
          <!-- {{viewSelectedFieldList}} -->
          <span
            class="dragItem pad_s"
            v-for="(element, index) in viewSelectedFieldList"
            :key="index"
            @click="allRightSelect(element, index)"
            :class="[element.required == '1' ? 'Selectes' : '']"
            :id="element.id"
            >{{ element.label }}
            <el-switch
              :disabled="element.defaultRequired"
              v-model="element.isRequier"
              active-color="#2D6CFC"
              inactive-color="#ada7a7"
            >
            </el-switch>
          </span>
        </draggable>
      </div>
    </div>

    <!-- 右侧操作按钮     -->
    <ul class="ulButton">
      <li>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('vue_label_normal_top')"
          placement="top-start"
          :open-delay="1000"
        >
          <el-button
            class="top"
            @click="top"
            @focus.native="topshow = false"
            @blur.native="topshow = true"
          >
            <svg class="icon" aria-hidden="true">
              <use :href="topshow ? '#icon-topBlack' : '#icon-topWhite'"></use>
            </svg>
          </el-button>
        </el-tooltip>
      </li>
      <li>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('vue_label_normal_moveup')"
          placement="top-start"
          :open-delay="1000"
        >
          <!-- <el-button
              type="primary"
              icon="el-icon-d-arrow-left"
              @click="top"
            ></el-button> -->
          <el-button
            class="left"
            @click="shiftUp"
            @focus.native="leftshow = false"
            @blur.native="leftshow = true"
          >
            <svg class="icon" aria-hidden="true">
              <use
                :href="leftshow ? '#icon-leftBlack' : '#icon-leftWhite'"
              ></use>
            </svg>
          </el-button>
        </el-tooltip>
      </li>
      <li>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('vue_label_normal_movedown')"
          placement="top-start"
          :open-delay="1000"
        >
          <el-button
            size="mini"
            class="right"
            @click="shiftDown"
            @focus.native="rightshow = false"
            @blur.native="rightshow = true"
          >
            <svg class="icon" aria-hidden="true">
              <use
                :href="rightshow ? '#icon-rightBlack' : '#icon-rightWhite'"
              ></use>
            </svg>
          </el-button>
        </el-tooltip>
      </li>
      <li>
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('vue_label_normal_bottom')"
          placement="top-start"
          :open-delay="1000"
        >
          <el-button
            class="bottom"
            @click="tail"
            @focus.native="bottomshow = false"
            @blur.native="bottomshow = true"
          >
            <svg class="icon" aria-hidden="true">
              <use
                :href="bottomshow ? '#icon-bottomBlack' : '#icon-bottomWhite'"
              ></use>
            </svg>
          </el-button>
        </el-tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "app",
  components: {
    draggable,
  },
  props: [
    "viewUnselectedFieldList",
    "viewSelectedFieldArr",
    "unselectedFieldList",
    "selectedFieldList",
    "title",
  ],
  // viewUnselectedFieldList 可选字段
  // viewSelectedFieldArr 已选字段
  data() {
    return {
      searchVal: "",
      newTask: "",
      originFields: [],
      ids: "",
      viewSelectedFieldList: [],
      copyList: [],
      nArr: [],
      shiftKey: null, //监听是否按下shift
      origin: -1, //记录起点
      allLeft: [], //左边穿梭框存放shift选中数据
      allRight: [], //右边穿梭框存放shift选中数据
      siteObj: {},
      index: null,
      moveArr: [],
      timeout: null,
      searchArr: [],
      loading: false,
      topshow: true,
      leftshow: true,
      rightshow: true,
      bottomshow: true,
      selectkey: 0,
    };
  },
  created() {},
  mounted() {
    // 弹框打开的时候清空装下标的数组
    this.$bus.$on("clearIndexArr", this.ebFn);

    if (this.viewUnselectedFieldList !== undefined) {
      if (this.viewUnselectedFieldList.length !== 0) {
        this.originFields = this.viewUnselectedFieldList;
        this.searchArr = this.viewUnselectedFieldList;
      }
    }
    this.viewSelectedFieldList = this.viewSelectedFieldArr;
    //监听是否按下shift事件，如果按下shift设置shiftKey为true
    window.addEventListener("keydown", this.keydownFn);
    //监听是否松开shift事件，如果按下shift设置shiftKey为false
    window.addEventListener("keyup", this.keyupFn);
  },
  beforeDestroy() {
    this.$bus.$off("clearIndexArr", this.ebFn);
    window.removeEventListener("keydown", this.keydownFn);
    window.removeEventListener("keyup", this.keyupFn);
  },
  methods: {
    ebFn(){
      this.moveArr = [];
      this.allRight = [];
    },
    keydownFn(e) {
      if (e.keyCode === 16 && e.shiftKey) {
        this.shiftKey = true;
      }
    },
    keyupFn() {
      this.shiftKey = false;
    },
    array_diff(a, b) {
      // 查询数据去重
      for (var i = 0; i < b.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (a[j].id == b[i].id) {
            a.splice(j, 1);
            j = j - 1;
          }
        }
      }
      return a;
    },
    checkSelIsEmpty(nval) {
      // 校验已选字段是否为空
      this.viewSelectedFieldList = nval;
      let selecteArr = [];
      if (this.viewSelectedFieldList !== undefined) {
        this.viewSelectedFieldList.forEach((val) => {
          selecteArr.push(val.id);
        });
        this.$emit("selFun", selecteArr.join(","));
      }
    },
    //左边穿梭框点击事件
    allLeftSelect(item, index) {
      // const data = this.originFields; //左边穿梭框数据
      const origin = this.origin; //起点标识
      const enIdx = index; //选中数据的index
      if (this.shiftKey) {
        //this.shiftKey为true时
        const sum = Math.abs(origin - enIdx) + 1; //记录终点
        const min = Math.min(origin, enIdx); //记录起点
        let i = 0;

        while (i < sum) {
          const index = min + i;
          this.originFields[index].required = "1"; //接口返回数据时添加required字段默认为false，如果选中则为true用以添加样式
          i++;
          this.allLeft.push(this.originFields[index]); //将shift选中的数据存放到数组中
        }
        this.unique(this.allLeft); //将左边存放shift数据的数组进行去重
        this.$forceUpdate();
      } else {
        //this.shiftKey为false时
        this.origin = index;
        if (item.required == "1") {
          //判断是否当前选中的字段是否为已选中状态
          item.required = "0"; //如果当前选中的字段是true则改为false
          this.allLeft.forEach((items, index) => {
            //将当前选中的字段是true改为false时删除this.allLeft中的这条数据
            if (items.id === item.id) {
              this.allLeft.splice(index, 1);
            }
          });
        } else {
          this.allLeft.push(item); //如果不为true则添加到this.allLeft中
          item.required = "1";
        }
        this.$forceUpdate();
      }
    },
    allLeftClick() {
      //点击向右按钮
      this.allLeft.forEach((item) => {
        item.required = "0"; //将选中状态改为false
        if (this.viewSelectedFieldList.length !== 15) {
          this.viewSelectedFieldList.push(item); //点击向右的按钮将状态为选中的数据存放到右边的穿梭框中
          this.originFields.forEach((items, index) => {
            if (item.id === items.id) {
              this.originFields.splice(index, 1); //删除左边穿梭框中被选中的数据
            }
          });
        } else {
          this.$message.error(
            this.$i18n.t("vue_label_commonobjects_view_to_display_fields")
          );
          this.allLeft = [];
        }
      });
      this.$nextTick(() => {
        this.unique(this.viewSelectedFieldList); //对右边穿梭框的数据进行去重
        this.allLeft = [];
      });
    },
    allRightSelect(item, index) {
      if (item.fieldname === "email") return;
      let xiabiao = index;
      // if(this.siteObj!={}){
      //   this.siteObj.required = false;//上一个点击的改为不选中样式
      // }
      // item.required = true;
      // this.$forceUpdate()

      this.siteObj = item;
      this.index = xiabiao;

      // this.moveArr.splice(0,1,index);//数组中只保存当前点击的元素下标
      // this.allRight.splice(0,1,this.viewSelectedFieldList[index]);

      // const data = this.viewSelectedFieldList; //右边穿梭框数据
      const origin = this.origin; //起点标识
      const enIdx = xiabiao; //选中数据的index

      if (this.shiftKey) {
        //this.shiftKey为true时
        const sum = Math.abs(origin - enIdx) + 1; //记录重点
        const min = Math.min(origin, enIdx); //记录起点
        let i = 0;
        while (i < sum) {
          const index = min + i;
          this.viewSelectedFieldList[index].required = "1"; //接口返回数据时添加required字段默认为false，如果选中则为true用以添加样式
          i++;
          this.allRight.push(this.viewSelectedFieldList[index]); //将shift选中的数据存放到数组中
          this.moveArr.push(index); //下标装进数组中
        }
        this.$forceUpdate();
      } else {
        //this.shiftKey为false时
        this.origin = xiabiao;
        if (item.required == "1") {
          item.required = "0"; //如果当前选中的字段是true则改为false
          this.allRight.forEach((items, index) => {
            //将当前选中的字段是true改为false时删除this.allLeft中的这条数据
            if (items.id === item.id) {
              this.allRight.splice(index, 1);
              this.moveArr.splice(xiabiao, 1);
            }
          });
          this.moveArr.splice(xiabiao, 1); //不是选中状态时将该下标移除
        } else {
          item.required = "1";
          this.allRight.push(item); //如果不为true则添加到this.allRight
          this.moveArr.push(xiabiao);
        }
        this.$forceUpdate();
      }
      this.unique(this.allRight);
      this.unique(this.moveArr); //去除重复的下标
    },
    allRightClick() {
      this.unique(this.allRight); //将右边存放shift数据的数组进行去重
      this.allRight.forEach((item) => {
        if (item.fieldname == "email") {
          // 成员名称
          this.$message({
            showClose: true,
            type: "warning",
            // message: this.$i18n.t("label_tabpage_delsuccessz"),
            message: this.$i18n.t("label.email_required"),
          });
          return;
        }
        item.required = "0"; //将选中状态改为false
        this.originFields.unshift(item); //点击向左的按钮将状态为选中的数据存放到右边的穿梭框中
        this.viewSelectedFieldList.forEach((items, index) => {
          if (item.id === items.id) {
            this.viewSelectedFieldList.splice(index, 1); //删除右边穿梭框中被选中的数据
          }
        });
      });
      this.$nextTick(() => {
        this.unique(this.originFields); //对左边穿梭框的数据进行去重
        this.allRight = [];
        this.moveArr = [];
      });
    },
    //监听左边穿梭框拖拽结束事件
    onEndLeft(e) {
      if (this.viewSelectedFieldList.length >= 16) {
        this.viewSelectedFieldList.forEach((item, index) => {
          if (item.id === e.clone.id) {
            this.$message.error(
              this.$i18n.t("vue_label_commonobjects_view_to_display_fields")
            );
            this.originFields.unshift(item);
            this.viewSelectedFieldList.splice(index, 1);
          }
        });
      } else {
        //拖拽结束将拖拽的记录从左边选中的数组中删除
        this.allLeft.forEach((item, index) => {
          if (item.id === e.clone.id) {
            this.allLeft.splice(index, 1);
          }
        });
      }
    },
    //监听右边穿梭框拖拽结束事件
    onEndRight(e) {
      //拖拽结束将拖拽的记录从右边选中的数组中删除
      this.allRight.forEach((item, index) => {
        if (item.id === e.clone.id) {
          this.allRight.splice(index, 1);
        }
      });
    },
    //数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    curGroupLists() {
      let selecteArr = [];
      this.viewSelectedFieldList.forEach((val) => {
        selecteArr.push(val.id);
      });
      this.$emit("memberFun", selecteArr.join(","));
    },
    //置顶
    top() {
      let indexArr = this.moveArr; //同理下移
      indexArr.sort(function (x, y) {
        return x - y;
      });
      indexArr.forEach((item, index) => {
        if (item != 0) {
          let arr = this.viewSelectedFieldList;
          arr.splice(index, 0, arr.splice(item, 1)[0]);
          this.viewSelectedFieldList = arr;
          indexArr.splice(index, 1, index); //数组中更新最新的下标
        }
      });
      this.moveArr = indexArr; //同理 下移
      this.$forceUpdate();
    },
    //置尾
    tail() {
      let indexArr = this.moveArr; //复制后排序
      indexArr.sort(function (x, y) {
        return y - x;
      });
      indexArr.forEach((item, index) => {
        let len = this.viewSelectedFieldList.length - 1;
        if (item != len) {
          let arr = this.viewSelectedFieldList;
          arr.splice(arr.length - index, 0, arr.splice(item, 1)[0]);
          this.viewSelectedFieldList = arr;
          indexArr.splice(index, 1, arr.length - index - 1); //数组中更新最新的下标
        }
      });
      this.moveArr = indexArr; //移动后重新在给回选中的数组中
      this.$forceUpdate();
    },
    //上移
    shiftUp() {
      let indexArr = this.moveArr; //同理下移
      indexArr.sort(function (x, y) {
        return x - y;
      });
      indexArr.forEach((item, index) => {
        if (item != 0) {
          let arr = this.viewSelectedFieldList;
          arr.splice(item - 1, 0, arr.splice(item, 1)[0]);
          this.viewSelectedFieldList = arr;
          indexArr.splice(index, 1, item - 1); //数组中更新最新的下标
        }
      });
      this.moveArr = indexArr; //同理 下移
      this.$forceUpdate();
    },
    //下移
    shiftDown() {
      let indexArr = this.moveArr; //复制后排序
      indexArr.sort(function (x, y) {
        return y - x;
      });
      indexArr.forEach((item, index) => {
        let len = this.viewSelectedFieldList.length - 1;
        if (item != len) {
          let arr = this.viewSelectedFieldList;
          arr.splice(item + 1, 0, arr.splice(item, 1)[0]);
          this.viewSelectedFieldList = arr;
          indexArr.splice(index, 1, item + 1); //数组中更新最新的下标
        }
      });
      this.moveArr = indexArr; //移动后重新在给回选中的数组中
      this.$forceUpdate();
    },
    //move回调方法
    // move事件用来控制那个元素不允许被拖拽和获取当前拖动元素的对象
    onMove(e) {
      // 不允许停靠
      // 
      if (e.relatedContext.element) {
        if (e.relatedContext.element.fieldname == "description") {
          this.selectkey++;
          if (this.selectkey == 1) {
            this.$message({
              showClose: true,
              type: "warning",
              // message: this.$i18n.t("label_tabpage_delsuccessz"),
              message: this.$i18n.t("label.email_required"),
            });
          }
          return false;
        }
        //不允许拖拽
        if (e.draggedContext.element.fieldname == "email") {
          if (e.from == e.to) {
            return true;
          } else {
            this.selectkey++;
            if (this.selectkey == 1) {
              this.$message({
                showClose: true,
                type: "warning",
                // message: this.$i18n.t("label_tabpage_delsuccessz"),
                message: this.$i18n.t("label.email_required"),
              });
            }

            return false;
          }
        }
      }
      return true;
    },
    // 搜索字段
    querySearchAsync(nval) {
      this.loading = true; //加载
      this.originFields = this.searchArr; //初始化
      if (nval !== "") {
        let res = this.originFields.filter((item) => {
          return (
            item.label &&
            item.label.toLowerCase().indexOf(nval.toLowerCase()) != -1
          );
        });
        this.originFields = res;
        this.loading = false;
      } else {
        this.originFields = this.searchArr;
        this.loading = false;
      }
    },
    switchFun() {
      this.checkSelIsEmpty(this.viewSelectedFieldList);
      this.$emit("memberfun", this.viewSelectedFieldList);
    },
  },
  watch: {
    // moveArr(n,o){
    //   
    // },
    // allRight(n,o){
    //   
    // },
    // shiftKey(n,o){
    //   
    // },
    viewSelectedFieldList(nval) {
      nval.forEach((val) => {
        // this.$set(val, "isRequier", false);
        if (val.required === "1") {
          this.$set(val, "isRequier", true);
        } else {
          this.$set(val, "isRequier", false);
        }
      });
      this.checkSelIsEmpty(nval);
      this.$emit("memberfun", nval);
    },
    viewSelectedFieldArr(nval) {
      this.checkSelIsEmpty(nval);
    },
    originFields(nval) {
      this.$emit("membernofun", nval);
    },
    unselectedFieldList(val) {
      this.loading = true;
      if (val.length !== 0) {
        this.originFields = val;
        this.searchArr = val;
        this.loading = false;
      }
    },
    selectedFieldList(val) {
      if (val.length !== 0) {
        this.viewSelectedFieldList = val;
      }
    },
    viewUnselectedFieldList(nval) {
      if (nval !== undefined) {
        if (nval.length !== 0) {
          this.originFields = nval;
          this.searchArr = nval;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  .dragBox {
    .shuttle {
      width: 190px;
      height: 420px;
      border: 1px solid #ddd;
      position: relative;
      .left_inp {
        width: 80%;
        margin-left: 0;
        position: absolute;
        left: 10px;
        top: 0;
        ::v-deep input {
          width: 90%;
          border: 0;
          background: none;
          padding: 0;
          outline: none;
          border: none;
          font-size: 12px;
        }
      }
    }
  }
}
.shu_left {
  height: 385px;
  border-top: 1px solid #ddd;
  cursor: move;
}
.right_box {
  width: 240px;
  height: 420px;
  border: 1px solid #ddd;
  position: relative;
  .tit_center {
    border-bottom: 1px solid #ccc;
    display: flex;
    span:first-child {
      display: inline-block;
      width: 60%;
      height: auto;
      text-align: center;
      line-height: 30px;
      font-size: 12px;
    }
    span:last-child {
      border-left: 1px solid #ccc;
      width: 39%;
      display: inline-block;
      height: auto;
      text-align: center;
      line-height: 30px;
      font-size: 12px;
    }
  }
}
.shu_right {
  cursor: move;
  .pad_s {
    padding-left: 10px;
    .el-switch {
      float: right;
      margin-top: 5px;
    }
  }
  span {
    padding-left: 20px;
  }
}
.shu_left span,
.shu_right span {
  font-size: 12px;
}

.itemBox {
  width: 100%;
  padding: 5px 0;
  overflow: auto;
}

.dragItem {
  width: 88%;
  //   height: 30px;
  display: inline-block;
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    background: #ddd;
  }
}

.btns {
  width: 100%;
  margin: 10px 15px 10px 0;
  text-align: right;
}

.font_style {
  font-size: 14px;
  color: #666666;
  font-weight: bold;
}
.icon_s {
  font-size: 20px;
  color: #999;
  margin-top: 10px;
}

::v-deep .Selectes {
  background: #ddd;
}

// ::v-deep .buttonGroup {
//   display: block;
//   float: left;
//   padding: 200px 10px;
//   display: flex;
// }

::v-deep .buttonGroup {
  display: block;
  float: left;
  padding: 200px 10px;
  display: flex;
  .lefts {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    &:focus {
      color: #fff;
    }
  }
  .rights {
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    &:focus {
      color: #fff;
    }
  }

  .el-button {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 3px !important;
    padding: 9px 2px !important;
    border: 1px solid #e2e2e2 !important;
  }
  .el-button:hover {
    background-color: #dceeff !important;
    border-color: #dceeff !important;
  }
  .el-button:focus {
    color: #ffffff !important;
    background-color: #2d6cfc !important;
    border-color: #2d6cfc !important;
  }
  .el-button--primary {
    border: 1px solid #e2e2e2 !important;
    color: #080707 !important;
    background-color: #ffffff !important;
    border-color: #e2e2e2 !important;
  }
}

::v-deep .ulButton {
  margin-top: 32px;
  margin-left: 10px;
  width: 26px;
  height: 103px;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  li {
    width: 26px;
    height: 26px;
    line-height: 26px !important;
    cursor: pointer;
    .el-button {
      border: none;
      margin: 0;
      height: 22px;
      width: 22px;
      padding: 0;
      margin-left: 5%;
    }
    .left {
      transform: rotate(90deg);
      background-repeat: no-repeat;
      &:hover {
        background-color: #dceeff !important;
        border-color: #dceeff !important;
      }
      &:focus,
      &:active {
        background-color: #2d6cfc !important;
        border-color: #2d6cfc !important;
      }
    }
    .right {
      text-align: center;
      background-repeat: no-repeat;
      background-position: center;
      transform: rotate(90deg);

      &:hover {
        background-color: #dceeff !important;
        border-color: #dceeff !important;
      }
      &:focus,
      &:active {
        background-color: #2d6cfc !important;
        border-color: #2d6cfc !important;
      }
    }
    .top {
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        background-color: #dceeff !important;
        border-color: #dceeff !important;
      }
      &:focus,
      &:active {
        background-color: #2d6cfc !important;
        border-color: #2d6cfc !important;
      }
    }
    .bottom {
      background-repeat: no-repeat;
      background-position: center;

      &:hover {
        background-color: #dceeff !important;
        border-color: #dceeff !important;
      }
      &:focus,
      &:active {
        background-color: #2d6cfc !important;
        border-color: #2d6cfc !important;
      }
    }
  }
}
.rightdragg {
  display: flex;
  justify-content: space-between;
}
</style>
