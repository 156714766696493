<!-- 静态开发:张聿通 -->
<template>
  <div class="top">
    <!-- 创建页 -->
    <div class="content" v-show="editFlg" @click="switchEmail">
      <div class="mainMail">
        <p class="mailText"></p>
        {{ $t("label.email.new") }}
      </div>
    </div>
    <!-- 预览页 -->
    <div class="editMail" v-show="!editFlg">
      <div class="emailHeader">
        <div class="emailName">
          <i class="el-icon-message"></i>
          <div class="emailTitle">{{ footerdataD.subjectline }}</div>
        </div>
        <div class="operationBox">
          <svg
            class="icon"
            aria-hidden="true"
            style="width: 16px; height: 16px; margin-right: 5px"
            @click="drawer = true"
          >
            <use href="#icon-editName"></use>
          </svg>
          <svg class="icon" aria-hidden="true" @click="delet()">
            <use href="#icon-system_delete_ic"></use>
          </svg>
        </div>
      </div>
      <div class="emailMain" v-html="footerdataD.emailbody"></div>
      <div class="footerShowBox">
        <div class="block">{{ footerdataD.companyname }}</div>
        <div class="block">{{ footerdataD.companystreet }}</div>
        <div class="block">{{ footerdataD.companydoorplate }}</div>
        <div class="block">{{ footerdataD.companycity }}</div>
        <div class="block">{{ footerdataD.companystate }}</div>
        <div class="block">{{ footerdataD.companyzipcode }}</div>
        <div class="block">{{ footerdataD.companycountry }}</div>
        <div class="block">{{ footerdataD.companyphone }}</div>
      </div>
    </div>
    <!-- 编辑页 -->
    <el-drawer
      :title="$t('label.email.track.edit')"
      :append-to-body="true"
      :visible.sync="drawer"
      :modal="false"
      :show-close="true"
      :before-close="handleClose"
      size="500px"
      :wrapperClosable="false"
    >
      <div class="editEmail">
        <!-- <div class="block">
          <i class="redInpormt">*</i>
          {{$t('vue_label_systemSettings_subscription')}}
        </div> -->
        <!-- <div class="block">
          <a>Edit email subscriptions</a> 
        </div> -->
        <!-- <div class="blockHeight">
          <el-select v-model="editObj.subs" placeholder="" class="selectMode">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="block">
          <i class="redInpormt">*</i>
          {{ $t("label.from_user") }}
        </div>
        <div class="blockHeight">
          <el-select
            v-model="footerdataD.fromuser"
            placeholder=""
            class="selectMode"
            multiple
            filterable
          >
            <el-option
              v-for="(item, itemid) in options"
              :key="itemid"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="block">
          {{ $t("label.sender_details") }}
          <a>{{ $t("label.settings_area") }}.</a>
        </div>
        <!-- <div class="block">From name</div>
        <div class="blockHeight">
          <el-input v-model="editObj.name" placeholder="" class="selectMode">
          </el-input>
        </div> -->
        <div class="block">
          <i class="redInpormt">*</i>
          {{ $t("label.subject_line") }}
        </div>
        <div class="blockHeight">
          <el-input
            v-model="footerdataD.subjectline"
            class="selectMode"
            :maxlength="100"
          >
          </el-input>
        </div>
        <div class="block">
          <i class="redInpormt">*</i>
          {{ $t("label.ems.body") }}
        </div>
        <!-- <div class="inputwindow">
          <div class="inup"></div>
          <div class="indown">
            <span>Thanks for submitting the form.</span>
          </div>
        </div> -->
        <wangEditer
          ref="editer"
          @editorContent="editorContent"
          :contents="footerdataemailbody"
        ></wangEditer>
        <div class="block">{{ $t("label.email_footer") }}</div>
        <div class="block">
          <a @click="editfooter" style="color:#409eff;cursor:pointer;">{{ $t("label.edit_emailfooter") }}</a>
        </div>
        <div class="block">{{ footerdataD.companyname }}</div>
        <div class="block">{{ footerdataD.companystreet }}</div>
        <div class="block">{{ footerdataD.companydoorplate }}</div>
        <div class="block">{{ footerdataD.companycity }}</div>
        <div class="block">{{ footerdataD.companystate }}</div>
        <div class="block">{{ footerdataD.companyzipcode }}</div>
        <div class="block">{{ footerdataD.companycountry }}</div>
        <div class="block">{{ footerdataD.companyphone }}</div>
        <div class="block">
          {{ $t("label.any_changes") }}
          <a>{{ $t("label.textmessage.vonage.settings") }}.</a>
        </div>
        <div class="demarcation"></div>
        <div class="footerBox">
          <el-button
            size="mini"
            type="primary"
            class="saveBt"
            @click="saveEdit"
          >
            {{ $t("component.telerecord.button.save") }}
          </el-button>
        </div>
      </div>
      <!-- 编辑email footer -->
      <el-drawer
        title="Email footer"
        :visible.sync="editDrawer"
        :modal="false"
        :show-close="true"
        :before-close="handleFooterClose"
        size="400px"
        :withHeader="false"
        :wrapperClosable="false"
      >
        <div class="footerHead">
          <i class="el-icon-arrow-left" @click="swicthEdit"></i>
          <span class="minus">|</span>
          <p class="efheader">{{ $t("label.email_footer") }}</p>
          <i class="el-icon-close" @click="handleFooterClose"></i>
        </div>
        <div class="editEmail">
          <div class="block">
            <i class="redInpormt">*</i>
            {{ $t("label.sms.companyname") }}
          </div>
          <div class="blockHeight">
            <el-input
              @input="oninput()"
              v-model.trim="footerdataD.companyname"
              placeholder=""
              class="selectMode"
            >
            </el-input>
          </div>
          <div class="block">
            <i class="redInpormt">*</i>
            {{ $t("AD") }}
          </div>
          <div class="blockHeight">
            <el-input
              @input="oninput()"
              v-model.trim="footerdataD.companystreet"
              placeholder=""
              class="selectMode"
            >
            </el-input>
          </div>
          <div class="block">{{ $t("label.address_linetwo") }}</div>
          <div class="blockHeight">
            <el-input
              @input="oninput()"
              v-model.trim="footerdataD.companydoorplate"
              placeholder=""
              class="selectMode"
            >
            </el-input>
          </div>
          <div class="block">
            <i class="redInpormt">*</i>
            {{ $t("label.city") }}
          </div>
          <div class="blockHeight">
            <el-input
              @input="oninput()"
              v-model.trim="footerdataD.companycity"
              placeholder=""
              class="selectMode"
            >
            </el-input>
          </div>
          <div class="block">
            <i class="redInpormt">*</i>
            {{ $t("label.address.province") }}
          </div>
          <div class="blockHeight">
            <el-input
              v-model.trim="footerdataD.companystate"
              @input="oninput()"
              placeholder=""
              class="selectMode"
            >
            </el-input>
          </div>
          <div class="block">{{ $t("System.AddressFieldType.Post") }}</div>
          <div class="blockHeight">
            <el-input
              @input="oninput()"
              v-model="footerdataD.companyzipcode"
              placeholder=""
              class="selectMode"
            >
            </el-input>
          </div>
          <div class="block">{{ $t("label.country") }}</div>
          <div class="blockHeight">
            <el-input
              @input="oninput()"
              v-model.trim="footerdataD.companycountry"
              placeholder=""
              class="selectMode"
            >
            </el-input>
          </div>
          <div class="block">
            {{ $t("label.appointmentconfirmail.wizard.subtitle6") }}
          </div>
          <div class="blockHeight">
            <el-input
              @input="oninput()"
              v-model.trim="footerdataD.companyphone"
              placeholder=""
              class="selectMode"
            >
            </el-input>
          </div>
        </div>
        <div class="demarcation"></div>
        <div class="footerBox">
          <el-button size="mini" type="primary" class="saveBt" @click="saved">
            {{ $t("component.task.button.save") }}
          </el-button>
        </div>
      </el-drawer>
    </el-drawer>
  </div>
</template>

<script>
import * as marketChannel from "./api"; //api
import wangEditer from "@/views/marketForm/components/editer/index.vue";
export default {
  components: { wangEditer },
  props: {
    selectlists: {
      type: Array,
      default: ()=>[],
    },
    footerdata: {
      type: Object,
      default: ()=>{},
    },
  },
  data() {
    return {
      footerdataD:this.footerdata,
      editObj: {
        subs: "",
        user: "",
        name: "",
      },
      footerShowList: [],
      footerList: {
        // fromuser: '',//用户邮箱
        // subjectline: '',//标题
        // emailbody: "",//富文本内容
        // companyname: '',//公司名称
        // companycountry: '',//国家
        // companycity: '',//城市
        // companystate: '',//省
        // companyzipcode: '',//邮件编码
        // companystreet: '',//地址
        // companydoorplate: '',//地址第二行
        // companyphone: '',//电话
        // subscriptiontype: 'aa',//订阅类型
      },
      emailContent: "<p>This is a paragraph.</p>",
      editDrawer: false,
      options: [],
      drawer: false,
      editFlg: true,
      mailStyle: "border: 1px solid #409EFF",
      footerdataemailbody: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.options = this.selectlists;
    // this.footerdataD.subscriptiontype = 'aa';
    // this.footerdataD.fromname = this.$store.state.creatform.itemDataId;
    if (Object.keys(this.footerdataD).length === 0) {
      this.getfooterdata();
    } else {
      this.editFlg = false;
    }
    let footer = JSON.stringify(this.footerdataD);
    this.footerList = JSON.parse(footer);
    if (this.footerdataD.emailbody) {
      this.footerdataemailbody = this.footerdataD.emailbody;
    }
  },
  //方法集合
  methods: {
    oninput() {
      this.$forceUpdate();
    },
    //保存信息
    saveEdit() {
      if (
        this.footerdataD.fromuser.length > 0 &&
        this.footerdataD.subjectline !== "" &&
        this.footerdataD.emailbody !== ""
      ) {
        let str = this.footerdataD.fromuser.join(",");
        this.footerList.fromuser = str;
        this.footerList.subjectline = this.footerdataD.subjectline;
        this.footerList.emailbody = this.footerdataD.emailbody;
        this.drawer = false;
        // 
        this.$store.state.creatform.emails = JSON.stringify([this.footerList]);
        this.footerList.fromuser = this.footerdataD.fromuser;
        this.$message.success(this.$i18n.t("label.search.saveok"));
      } else {
        this.drawer = true;
        this.$message.error(this.$i18n.t("vue_label_lead_required_fields"));
      }
    },
    //初始状态
    init() {
      this.editObj = {
        subs: "",
        user: "",
        name: "",
      };
    },
    // 保存页脚信息
    saved() {
      if (
        this.footerdataD.companyname !== "" &&
        this.footerdataD.companystreet !== "" &&
        this.footerdataD.companycity !== "" &&
        this.footerdataD.companystate !== ""
      ) {
        this.editDrawer = false;
        this.drawer = true;
        this.footerList.companyname = this.footerdataD.companyname;
        this.footerList.companycountry = this.footerdataD.companycountry;
        this.footerList.companycity = this.footerdataD.companycity;
        this.footerList.companystate = this.footerdataD.companystate;
        this.footerList.companyzipcode = this.footerdataD.companyzipcode;
        this.footerList.companystreet = this.footerdataD.companystreet;
        this.footerList.companydoorplate = this.footerdataD.companydoorplate;
        this.footerList.companyphone = this.footerdataD.companyphone;
      } else {
        this.editDrawer = true;
        this.drawer = true;
        this.$message.error(this.$i18n.t("vue_label_lead_required_fields"));
      }
    },
    editorContent(val) {
      // this.footerdataD.emailbody = val;
      this.$set(this.footerdataD, "emailbody", val);
    },
    //切换回编辑页
    swicthEdit() {
      this.editDrawer = false;
      this.drawer = true;
    },
    //关闭设置footer页
    handleFooterClose() {
      this.footerdataD.companyname = this.footerList.companyname;
      this.footerdataD.companycountry = this.footerList.companycountry;
      this.footerdataD.companycity = this.footerList.companycity;
      this.footerdataD.companystate = this.footerList.companystate;
      this.footerdataD.companyzipcode = this.footerList.companyzipcode;
      this.footerdataD.companystreet = this.footerList.companystreet;
      this.footerdataD.companydoorplate = this.footerList.companydoorplate;
      this.footerdataD.companyphone = this.footerList.companyphone;

      this.editDrawer = false;
      this.drawer = true;
    },
    //关闭侧页方法
    handleClose() {
      if (this.footerList.fromuser) {
        this.footerdataD.fromuser = this.footerList.fromuser;
      } else {
        this.footerdataD.fromuser = [];
      }

      if (this.footerList.subjectline) {
        this.footerdataD.subjectline = this.footerList.subjectline;
      } else {
        this.footerdataD.subjectline = "";
      }

      if (this.footerList.emailbody) {
        this.$refs.editer.clearvalue();
        this.footerdataD.emailbody = this.footerList.emailbody;
        this.footerdataemailbody = this.footerList.emailbody;
        this.$refs.editer.inserthtml(this.footerdataemailbody);
      } else {
        this.$refs.editer.clearvalue();
        this.footerdataD.emailbody = "";
        this.footerdataemailbody = "";
        this.$refs.editer.inserthtml(this.footerdataemailbody);
      }

      this.drawer = false;
      this.editDrawer = false;
    },
    editfooter() {
      this.editDrawer = true;
    },
    switchEmail() {
      this.editFlg = false;
    },
    getfooterdata() {
      let obj = {
        id: "MarketcloudBaseInfo",
      };
      marketChannel
        .getSettingDetail(obj)
        .then((res) => {
          if (res.result) {
            this.footerdataD.companyname = res.data.companyName;
            this.footerdataD.companycountry = res.data.companyCountry;
            this.footerdataD.companycity = res.data.companyCity;
            this.footerdataD.companystate = res.data.companyState;
            this.footerdataD.companyzipcode = res.data.companyZipcode;
            this.footerdataD.companystreet = res.data.companyStreet;
            this.footerdataD.companydoorplate = res.data.companyDoorplate;
            this.footerdataD.companyphone = res.data.companyPhone;
            let footer = JSON.stringify(this.footerdataD);
            this.footerList = JSON.parse(footer);
          }
        })
        .catch(() => {
          return false;
        });
    },
    delet() {
      // c277 c278
      this.$confirm(this.$i18n.t("c277"), this.$i18n.t("c278"), {
          cancelButtonText: this.$i18n.t("label.tabpage.cancel"),
          confirmButtonText: this.$i18n.t("c280"),//c280
        }).then(() => {
          this.$message({
            type: 'success',
            message: this.$i18n.t("label.tabpage.delsuccessz")
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: this.$i18n.t("label.delete.cancel")
          });          
        });
      this.editFlg = true;
      this.footerdataD = {};
      this.footerList.fromuser = [];
      this.footerList.subjectline = "";
      this.footerList.emailbody = "";
      this.getfooterdata();
    },
  },
};
</script>
<style lang='scss' scoped>
.icon {
  display: inline-block;
  margin-left: 4px;
  cursor: pointer;
}
.el-select-dropdown__item.selected::after {
  position: static !important;
  float: right;
}
.footerShowBox {
  text-align: left;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #080707;
  letter-spacing: 0;
  margin: 10px;
}
.el-icon-close {
  float: right;
}
.minus {
  color: #dad8d8;
  margin-right: 10px;
}
.el-icon-arrow-left,
.minus,
.el-icon-close {
  font-size: 24px;
  margin-top: 10px;
}
.footerHead {
  height: 50px;
  border-bottom: 1px solid #ddd6d6;
}
.demarcation {
  margin: 0px;
  height: 2px;
  background-color: #ddd6d6;
}
.efheader {
  display: inline-block;
  font-family: MicrosoftYaHei-Bold;
  font-size: 18px;
  color: #3e3c3c;
  letter-spacing: 0;
  font-weight: bold;
}
.footerBox {
  height: 60px;
  .saveBt {
    margin: 20px 10px 0px 0px;
    float: right;
  }
}
::v-deep .el-drawer__header {
  font-family: MicrosoftYaHei-Bold;
  font-size: 18px;
  color: #3e3c3c;
  letter-spacing: 0;
  font-weight: bold;
}
.inputwindow {
  width: 444px;
  min-height: 247px;
  background: #ffffff;
  border: 1px solid #dedcda;
  border-radius: 3px;
  .inup {
    height: 50px;
    background: #f5f5f5;
    border-bottom: 1px solid #dedcda;
  }
}
.selectMode {
  width: 95%;
}
.redInpormt {
  color: #c23934;
  margin-left: -12px;
}
::v-deep .el-drawer {
  text-align: left;
  overflow-y: auto;
}
.editEmail {
  display: flex;
  flex-direction: column;
  margin: 10px 10px 20px 30px;
  .block {
    margin: 5px 0px;
  }
  .blockHeight {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.top {
  width: 100%;
  height: 100%;
  text-align: center;
  .editMail {
    width: 850px;
    min-height: 450px;
    margin-top: 30px;
    margin-bottom: 100px;
    display: inline-block;
    background: #ffffff;
    border: 1px solid #dedcda;
    border-radius: 2px;
    border-radius: 2px;
    .emailHeader {
      background-color: #dedcda;
      height: 50px;
      .emailName {
        i {
          margin: 20px 20px 0px 20px;
          font-size: 16px;
        }
        .emailTitle {
          margin-top: 14px;
          float: right;
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          color: #080707;
          letter-spacing: 0;
        }
        float: left;
      }
      .operationBox {
        margin: 15px;
        float: right;
        img {
          cursor: pointer;
        }
      }
    }
    .emailMain {
      min-height: 100px;
      padding: 20px 10px;
      text-align: left;
    }
  }
}
.mainMail{
  border: 1px solid #409eff;
}
.mainMail:hover {
  border: 1px solid #409eff;
}
.content {
  width: 100%;
  .graphics {
    position: absolute;
    width: 68px;
    height: 68px;
    left: 48%;
    top: 62%;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    background-color: #2D6CFC;
    .el-icon-plus {
      position: absolute;
      left: 20%;
      top: 20%;
      font-size: 43px;
      color: #ffffff;
    }
  }
  .addText {
    position: absolute;
    left: 48%;
    top: 73%;
  }
  .mainMail {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 60%;
    height: 7.5%;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    background-color: #ffffff;
    .mailText {
      height: 7.5%;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
    }
  }
}
</style>