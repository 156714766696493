<!--  -->
<template>
  <div class="followBox">
    <div class="main">
      <div class="mainTitle">
        {{ $t("label.follow_emails") }}
      </div>
      <svg class="icon sendImg" aria-hidden="true">
        <use href="#icon-notice"></use>
      </svg>
      <el-button class="followBt" size="mini" type="primary" @click="swicth">{{
        $t("label.created_followup")
      }}</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  //方法集合
  methods: {
    swicth() {
      this.$emit('swicth')
    }
  },
}
</script>
<style lang='scss' scoped>
.followBox {
  display: flex;
  justify-content: center;
  width: 100%;
  .main {
    margin-top: 100px;
    .sendImg {
      display: block;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 50px;
      width: 234px;
      height: 142px;
    }
    .mainTitle,
    .lastTitle {
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: #080707;
      letter-spacing: 0;
      text-align: center;
      line-height: 24px;
    }
    .followBt {
      display: block;
      margin: 0 auto;
    }
  }
}
</style>