<!--  -->
<template>
  <div class="">
    <editFollow
      v-if="!switchFlg"
      :selectlists="selectlists"
      :footerdata="footerdata"
    ></editFollow>
    <createFollow v-if="switchFlg" @swicth="swicth"></createFollow>
  </div>
</template>

<script>
import editFollow from "@/views/marketForm/createFrom/followUp/editFollow.vue";
import createFollow from "@/views/marketForm/createFrom/followUp/createFollow.vue";
import * as marketChannel from "./api"; //api
export default {
  components: { editFollow, createFollow },
  data() {
    return {
      switchFlg: true,
      selectlists: [],
      footerdata: {},
    };
  },
  created() {
    this.getformuser();
  },
  //方法集合
  methods: {
    swicth() {
      this.switchFlg = false;
    },
    //获取用户邮箱
    getMember(params) {
      return new Promise(function (resolve, reject) {
        marketChannel
          .getUsersEmail(params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    //获取form详细信息
    getFormdata(params) {
      return new Promise(function (resolve, reject) {
        marketChannel
          .getFormdetail(params)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getformuser() {
      let params = {
        id: this.$store.state.creatform.itemDataId,
      };

      Promise.all([this.getMember(params), this.getFormdata(params)])
        .then((res) => {
          this.selectlists = res[0].data.emailList;

          if (res[1].data.emailList.length > 0) {
            let obj = res[1].data.emailList[0];
            obj.fromuser = obj.fromuser.split(",");
            this.footerdata = obj;
            this.switchFlg = false;
          } else {
            this.footerdata = {};
          }
        })
        // marketChannel.getFormdetail(obj).then((res) => {
        //   if (res.result) {
        //     this.selectlists = res.data.fromUserList;

        //     if (res.data.emailList.length > 0) {
        //       this.footerdata = res.data.emailList[0];
        //       this.switchFlg = false;
        //     } else {
        //       this.footerdata = {};
        //     }
        //   }
        // })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>
<style lang='scss' scoped>
</style>