!<!-- 静态开发：张聿通 -->
<template>
  <!-- 新增/修改记录 -->
  <div class="stepDialog">
    <div class="pull-left" style="width: 100%">
      <div class="groupTitle">
        <span>{{ nextTitle }}</span>
        <div class="btn">
          <el-button
            type="primary"
            v-show="currentStep !== 2"
            size="mini"
            @click="preStep"
          >
            {{ $t("label.import.page1.laststep") }}
          </el-button>
          <el-button
            v-show="currentStep !== 4"
            size="mini"
            type="primary"
            @click="nextStep"
          >
            {{ $t("label.import.page1.nextstep") }}
          </el-button>
          <el-button
            v-show="currentStep === 4"
            size="mini"
            type="primary"
            @click="completeForm"
            v-loading="saveLoading"
          >
            <!-- 完成 -->
            {{ $t("label.emailtocloudcc.button.complete") }}
          </el-button>
        </div>
      </div>
    </div>
    <div
      class="steps"
      :style="{
        minHeight: dialogBodyHeight,
      }"
    >
      <!--步骤1-->
      <stepFive ref="fiveStep" v-show="currentStep === 2"> </stepFive>
      <stepThr ref="thrStep" :baidudata="baidudata" v-show="currentStep === 3">
      </stepThr>
      <!-- <stepFour
        :key="num"
        :stepFourNoSelectList="stepFourNoSelectList"
        :stepFourSelectedList="stepFourSelectedList"
        :baidudata="baidudata"
        ref="fourStep"
        v-show="currentStep === 3"
      >
      </stepFour> -->
      <stepTwo
        ref="twoStep"
        :baidudata="baidudata"
        :viewSelectedFieldList="viewSelectedFieldList"
        v-show="currentStep === 4"
      >
      </stepTwo>
    </div>
  </div>
</template>

<script>
import Bus from "@/mixin/bus.js";
import stepTwo from "@/views/marketForm/createFrom/fromType/stepDialog/stepContent/stepTwo.vue";
import stepThr from "@/views/marketForm/createFrom/fromType/stepDialog/stepContent/stepThr.vue";
import stepFive from "@/views/marketForm/createFrom/fromType/stepDialog/stepContent/stepFive.vue";
import * as marketChannel from "../api";
export default {
  name: "index",
  components: {
    stepTwo,
    stepThr,
    stepFive,
  },
  props: {
    isShowDialog: {
      // 是否显示弹框
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogTitle: this.$i18n.t("label.campaign.channel.edit"),
      dialogBodyHeight: `${document.body.offsetHeight - 370}px`,
      currentStep: 2,
      totalStep: 4,
      stepFourNoSelectList: [],
      stepFourSelectedList: [],
      num: 1,
      count: 0,
      number: 2,
      registeredPageRequiredlist: [],
      registeredPageNoRequiredlist: [],
      fields: [], // 生成注册页面的字段信息
      sourceCode: "", // 源码
      sourceCodeFlg: false,
      sixStepFormData: {}, // 第六步的数据
      baidudata: {}, //页面布局信息
      viewSelectedFieldList: [], //选择字段
      saveLoading: false, //保存中
    };
  },
  computed: {
    nextTitle() {
      if (this.currentStep === 2) {
        return this.$i18n.t("c319"); //'表单外观设置'
      } else if (this.currentStep === 3) {
        // return this.$i18n.t("registration.page.field.selection")
        return this.$i18n.t("c320"); //'表单字段选择'
      } else if (this.currentStep === 4) {
        return this.$i18n.t("c470"); //表单静默填写字段
        // } else if (this.currentStep === 5) {c470
        // return this.$i18n.t("front-market-form-createdsecond")
      } else {
        return "";
      }
    },
  },
  mounted() {
    Bus.$on("windowResize",this.ebFn);
  },
  beforeDestroy() {
    Bus.$off("windowResize",this.ebFn);
  },
  watch: {},
  methods: {
    ebFn (offsetHeight) {
      this.dialogBodyHeight = `${offsetHeight - 370}px`;
    },
    completeForm() {
      this.savedata();
    },
    // 上一步
    preStep() {
      if (this.currentStep == 4) {
        this.stepFourNoSelectList =
          this.$refs.thrStep.getStepFourNoSelectInitList();
        this.stepFourSelectedList =
          this.$refs.thrStep.getStepFourSelectedInitList();
      }
      if (this.currentStep !== 1) {
        this.currentStep--;
      } else {
        this.currentStep = 1;
      }
    },
    //下一步
    nextStep() {
      if (this.currentStep === 2) {
        this.$refs.fiveStep.getImg();
        let baiduobj = {};
        if (this.$refs.fiveStep.stepFiveformData.location == "") {
          baiduobj.center = {
            lat: "",
            lng: "",
          };
        } else {
          baiduobj.center = this.$refs.fiveStep.center;
        }

        baiduobj.actlocat = this.$refs.fiveStep.actlocat;
        baiduobj.location = this.$refs.fiveStep.stepFiveformData.location;
        baiduobj.subject = this.$refs.fiveStep.stepFiveformData.subject;
        baiduobj.remark = this.$refs.fiveStep.stepFiveformData.remark;
        baiduobj.bgimage = this.$refs.fiveStep.stepFiveformData.bgimage;
        baiduobj.logo = this.$refs.fiveStep.stepFiveformData.logo;
        this.baidudata = baiduobj;
        if (!this.$refs.fiveStep.stepFiveformData.subject) {
          this.$message({
            showClose: true,
            message: this.$i18n.t("label.h5.new.errorbody"),
            type: "warning",
          });
          this.currentStep = 1;
        } else {
          this.$nextTick(() => {
            this.$refs.fiveStep.getImg();
          });
        }
      }
      if (this.currentStep === 3) {
        this.viewSelectedFieldList = this.$refs.thrStep.stepThrData;
      }
      this.currentStep++;
      if (this.currentStep === 4 ) {
        if(this.viewSelectedFieldList&& this.viewSelectedFieldList.length>0){
          this.$refs.twoStep.init(this.viewSelectedFieldList)
        }else{
          this.currentStep = 3
          this.$message.error(this.$i18n.t("c572"))
        }
        if (this.$store.state.creatform.objectid !== "201200000108754gEHnj") {
          let stepArr = this.$refs.thrStep.stepThrNoSelectList;
          stepArr.forEach((val) => {
            this.$set(val, "labelName", val.label);
            this.$set(val, "datafieldRef", val.fieldname);
          });
          this.$refs.twoStep.selectFieldList = stepArr;
        }
      }
    },
    savedata() {
      if (this.saveLoading) return;
      this.saveLoading = true;
      if (this.currentStep === 4 || this.$store.state.creatform.itemDataId) {
        let params = {};
        if (this.$store.state.creatform.itemDataId) {
          params.id = this.$store.state.creatform.itemDataId; // id
        } else {
          // 新建
          params.id = "";
          params.rtntype = "";
          params.rtnurl = "";
          params.userEmailIds = "";
          params.emailList = "";
        }
        params.longitude = this.$refs.fiveStep.center.lng;
        params.latitude = this.$refs.fiveStep.center.lat;
        params.address = this.$refs.fiveStep.actlocat;
        params.channel = this.$refs.fiveStep.stepFiveformData.subject;
        params.campaignId = this.$store.state.channel.campaignId; // 市场活动id
        params.objectid = this.$store.state.creatform.objectid;
        params.ownerobjectid = this.$store.state.creatform.ownerobjectid;
        let fourarr = [];
        let stepgour = this.$refs.thrStep
          .getStepFourSelectedInitList()
          // .concat(this.$refs.thrStep.getStepFourNoSelectInitList());
        stepgour.forEach((item) => {
          fourarr.push({
            fieldname: item.fieldname,
            required: item.required?item.required:"0",
            label: item.label,
            objectid: item.objectid,
            ownerobjectid: item.ownerobjectid,
          });
        });
        params.fields = JSON.stringify(fourarr); // 生成注册页面的字段信息

        let leadvalue = this.$refs.twoStep.stepTwoFormData.leadDefaultValues;
        let arr = [];
        for (let i = 0; i < leadvalue.length; i++) {
          let leadobj = {};
          if (leadvalue[i].fieldid !== "" && leadvalue[i].dvalue !== "") {
            leadobj.fieldname = leadvalue[i].fieldid;
            leadobj.dvalue = leadvalue[i].dvalue;
            leadobj.objectid = leadvalue[i].objectid;
            arr.push(leadobj);
          }
        }
        params.leadDefaultValues = JSON.stringify(arr); // 潜在客户默认值的设置
        params.modelType = this.$store.state.creatform.selectTempFlg;
        marketChannel
          .getFormdetail(params)
          .then((res) => {
            if (res.result) {
              params.rtntype = res.data.rtntype;
              params.rtnurl = res.data.rtnurl;
              params.userEmailIds = JSON.stringify(res.data.userEmailIds);
              params.emailList = res.data.emailList;
            }
          })
          .catch(() => {
            return false;
          });
        if (this.$refs.fiveStep.stepFiveformData.remark) {
          this.$refs.fiveStep.stepFiveformData.remark = JSON.stringify(
            this.$refs.fiveStep.stepFiveformData.remark
          );
        } else {
          this.$refs.fiveStep.stepFiveformData.remark = "";
        }

        let objParams = { ...params, ...this.$refs.fiveStep.stepFiveformData };
        marketChannel
          .getFormPageContent(objParams)
          .then((res) => {
            objParams.pageContent = res.data.pageContent;
            if (this.$store.state.creatform.itemDataId) {
              marketChannel.getFormdetail(objParams).then((res) => {
                if (res.result) {
                  objParams.rtnType = res.data.rtntype;
                  objParams.rtnUrl = res.data.rtnurl;
                  objParams.userEmailIds = res.data.userEmailIds;
                }
              });
            } else {
              objParams.rtntype = "";
              objParams.rtnurl = "";
              objParams.userEmailIds = "";
              objParams.emailList = "";
            }
            marketChannel
              .saveForm(objParams)
              .then((re) => {
                this.saveLoading = false;
                if (res.result) {
                  if (this.$store.state.creatform.itemDataId == "") {
                    this.$store.state.creatform.itemDataId = re.data.id;
                    this.$store.state.creatform.actionType == "edit";
                    this.$message.success(
                      this.$i18n.t("label.create_successful")
                    );
                    this.$emit("complete");
                  } else {
                    this.$message.success(
                      this.$i18n.t("vue_label_notice_edit_success")
                    );
                    this.$emit("complete");
                  }
                } else {
                  this.$message.error(this.$i18n.t("label.create_failure"));
                }
              })
              .catch(() => {
                this.saveLoading = false;
                return false;
              });
          })
          .catch(() => {
            return false;
          });
      }
    },
    complete() {
      this.$emit("closeDialog");
      this.currentStep = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.groupTitle {
  height: 40px;
  font-size: 16px;
  padding-left: 24px;
  padding-right: 15px;
  margin-bottom: 20px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dddbda;
  span {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  .btn {
    .el-button {
      padding: 5px 15px;
      height: 30px;
      background: #2d6cfc;
      border-radius: 4px;
      ::v-deep span {
        width: 48px;
        font-size: 12px;
        color: #ffffff;
        line-height: 18px;
      }
    }
  }
}
.step {
  font-size: 12px;
  color: #080707;
}
.steps {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
