import axios from '@/config/httpConfig'
//获取市场云配置信息
export function getSettingDetail(data) {
    return axios.post('/marketcloud/getSettingDetail', data)
}
//获取所有用户的邮箱信息
export function getUsersEmail(data) {
    return axios.post('/form/getUsersEmail', data)
}
//获取form详细信息
export function getFormdetail(data) {
    return axios.post('/form/getFormDetail', data)
}