<template>
  <div class="base-form">
    <el-form
      :status-icon="true"
      ref="stepFiveForm"
      :model="stepFiveformData"
      class="clearfix"
      :validate-on-rule-change="false"
      :label-position="labelPosition"
      :rules="rules"
    >
      <div style="width: 100%">
        <div>
          <div
            style="width: 100%"
            v-if="$store.state.creatform.selectTempFlg !== 'nullModel'"
          >
            <el-form-item
              label="Logo"
              style="min-height: 38px"
              label-width="100px"
            >
              <el-upload
                class="avatar-uploader"
                action="/test"
                :show-file-list="false"
                :on-success="logoHandleAvatarSuccess"
                :before-upload="logoBeforeAvatarUpload"
              >
                <img
                  v-if="stepFiveformData.logo"
                  :src="imgSrc(stepFiveformData.logo)"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" style="color: #c12217; font-size: 12px">
                  {{ $t("label.twomb") }}
                  <!-- 只能上传jpg/png文件，且不超过2MB -->
                </div>
              </el-upload>
              <el-button
                class="del_btn"
                v-if="stepFiveformData.logo"
                type="primary"
                size="small"
                @click="imgDelete('logo')"
                >{{ $t("label.delete") }}</el-button
              >
            </el-form-item>
          </div>
          <div>
            <el-form-item
              v-if="$store.state.creatform.selectTempFlg == 'generalMode'"
              label="Banner"
              style="min-height: 38px"
              label-width="100px"
            >
              <el-upload
                class="avatar-uploader"
                action="/test"
                :show-file-list="false"
                :on-success="bgHandleAvatarSuccess"
                :before-upload="bgBeforeAvatarUpload"
              >
                <img
                  v-if="stepFiveformData.bgimage"
                  :src="imgSrc(stepFiveformData.bgimage)"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-iconsecond"></i>
                <div slot="tip" style="color: #c12217; font-size: 12px">
                  {{ $t("label.twomb") }}
                  <!-- 只能上传jpg/png文件，且不超过2MB -->
                </div>
              </el-upload>
              <el-button
                class="del_btn"
                v-if="stepFiveformData.bgimage"
                type="primary"
                size="small"
                @click="imgDelete('bgimage')"
                >{{ $t("label.delete") }}</el-button
              >
            </el-form-item>
            <el-form-item
              v-if="$store.state.creatform.selectTempFlg == 'positioningModel'"
              :label="$t('label.address_information')"
              style="min-height: 38px"
              label-width="100px"
            >
              <el-input
                v-model="stepFiveformData.location"
                :placeholder="$t('label.partnerCloud.enterAddress')"
                clearable
                @focus="see = true"
              ></el-input>
              <div
                class="superMap"
                id="superMap"
                v-if="stepFiveformData.location"
              >
                <baidu-map
                  class="bm-view"
                  :center="center"
                  :zoom="zoom"
                  @ready="handler"
                  :scroll-wheel-zoom="true"
                >
                  <bm-marker :position="center"> </bm-marker>
                  <bm-local-search
                    class="bm_search"
                    ref=""
                    @infohtmlset="infohtmlset"
                    :keyword="stepFiveformData.location"
                    :location="null"
                    :pageCapacity="5"
                    v-if="see"
                    style="z-index: 99999"
                  ></bm-local-search>
                </baidu-map>
                <p
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                  v-if="actlocat"
                >
                  {{ $t("AD") }}：{{ actlocat }}
                </p>
              </div>
            </el-form-item>
          </div>
          <div style="width: 100%; margin-top: 30px">
            <el-form-item
              prop="subject"
              :label="$t('label.form.title')"
              style="min-height: 38px"
              label-width="100px"
            >
              <el-input
                v-model="stepFiveformData.subject"
                :placeholder="$t('label.form.title')"
                clearable
                maxlength="20"
                show-word-limit
              ></el-input>
            </el-form-item>
          </div>
          <div style="width: 100%; margin-top: 30px">
            <el-form-item
              :label="$t('label.introduction.the.form')"
              style="min-height: 38px"
              label-width="100px"
            >
              <el-input
                type="textarea"
                class="textar"
                v-model="stepFiveformData.remark"
                :placeholder="$t('c151')"
                clearable
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
    <div class="selectImg">
      <div class="baglog">
        <div class="smallrema">
          <div v-if="stepFiveformData.logo" class="smallog">
            <img :src="imgSrc(stepFiveformData.logo)" alt="" />
          </div>
          <div
            v-if="
              stepFiveformData.bgimage &&
              $store.state.creatform.selectTempFlg == 'generalMode'
            "
            class="samllgeneralMode"
          >
            <img :src="imgSrc(stepFiveformData.bgimage)" alt="" />
          </div>
          <h1 v-if="stepFiveformData.subject">
            {{ stepFiveformData.subject }}
          </h1>
          <el-input
            type="textarea"
            class="smallp"
            v-if="stepFiveformData.remark"
            :autosize="{ minRows: 0, maxRows: 999 }"
            v-model="stepFiveformData.remark"
            readonly
          ></el-input>
          <div v-if="StepThreeData.length == 0" class="ziduan">
            <div v-for="sel in arr" :key="sel.fieldname" class="selected">
              <p>
                {{ sel.label }}
                <span v-if="sel.required === '1'">*</span>
              </p>
              <input type="text" disabled />
            </div>
          </div>
          <div v-if="StepThreeData.length > 0" class="selectlists">
            <div
              v-for="sel in StepThreeData"
              :key="sel.fieldname"
              class="selected"
            >
              <p>
                {{ sel.label }}
                <span v-if="sel.required === '1'">*</span>
              </p>
              <input type="text" disabled />
            </div>
          </div>
          <div
            class="baiduapp"
            v-if="
              $store.state.creatform.selectTempFlg == 'positioningModel' &&
              stepFiveformData.location
            "
          >
            <baidu-map
              class="baiduview"
              :center="center"
              zoom="19"
              :scroll-wheel-zoom="true"
            >
              <bm-marker :position="center"> </bm-marker>
            </baidu-map>
            <p v-if="actlocat">{{ $t("AD") }}：{{ actlocat }}</p>
          </div>
          <div class="baidusub">
            <button>
              {{ $t("label.submit") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as marketChannel from "../../api"; //api
import * as CommonObjApi from "./api";
import Vue from "vue";
import BaiduMap from "vue-baidu-map"; //引入百度地图组件库
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "6XPhZVfaUKeRAU9Q4X9zRwtiNGouGdzl",
});
export default {
  name: "stepFive",
  props: {},
  data() {
    return {
      StepThreeData: [],
      arr: [
        {
          label: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
        {
          label: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
        {
          label: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
      ],
      center: {
        // lat: '39.915',
        // lng: '116.404'
      }, //地图坐标
      zoom: 19, //地图倍数
      formName: {
        location: "",
        longitude: "",
        latitude: "",
        address: "",
      },

      labelPosition: "right", // 标签位置
      stepFiveformData: {
        location: "",
        subject: "",
        remark: "",
        bgimage: "", // 图片id
        logo: "",
      },
      fileType: ["jpg", "png", "jpeg"], // 上传图片的类型
      token: this.$CCDK.CCToken.getToken(),
      rules: {
        subject: [
          {
            required: true,
            message: this.$i18n.t("label.file.enter.title"),
            trigger: "blur",
          },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
      actlocat: "",
      see: true,
      objectid: "",
      ownerobjectid: "",
    };
  },
  created() {
    this.getStepFiveData();
    this.getStepThreeData();
    this.stepFiveformData.subject = this.$store.state.creatform.channel;
  },
  watch: {
    "stepFiveformData.subject"() {
      this.$store.state.creatform.channel = this.stepFiveformData.subject;
    },
    "$store.state.creatform.channel"() {
      this.stepFiveformData.subject = this.$store.state.creatform.channel;
    },
    "stepFiveformData.location"() {
      if (this.stepFiveformData.location == "") {
        this.center = {
          lat: "",
          lng: "",
        };
      }
    },
  },
  methods: {
    //地图坐标
    handler() {},
    //地图
    infohtmlset(pois) {
      this.stepFiveformData.location = pois.title;
      this.center = pois.point;
      // this.center.lat = pois.point.lat;
      // this.center.lng = pois.point.lng;
      this.actlocat = pois.address;
      this.see = false;
      // let urlmap = 'http://api.map.baidu.com/staticimage/v2?ak=6XPhZVfaUKeRAU9Q4X9zRwtiNGouGdzl&markers=' + this.stepFiveformData.location + '&center=' + this.objmap.lng + ',' + this.objmap.lat + '&width=300&height=200&zoom=16&markerStyles=m,Y,red,wfwefw'
      // this.stepFiveformData.bgimage = urlmap;
    },
    //获取地图img
    getImg() {
      //let urlmap = 'http://api.map.baidu.com/staticimage/v2?ak=6XPhZVfaUKeRAU9Q4X9zRwtiNGouGdzl&width=260&height=106&center=' + this.objmap.lat + ',' + this.objmap.lng + '&zoom=4';

      if (
        this.$store.state.creatform.selectTempFlg !== "generalMode" &&
        this.stepFiveformData.location
      ) {
        let urlmap =
          "http://api.map.baidu.com/staticimage/v2?ak=6XPhZVfaUKeRAU9Q4X9zRwtiNGouGdzl&markers=" +
          this.stepFiveformData.location +
          "&center=" +
          this.center.lng +
          "," +
          this.center.lat +
          "&width=720&height=150&zoom=16&markerStyles=m,Y,0xFF0000";
        this.stepFiveformData.bgimage = "";
        this.stepFiveformData.bgimage = urlmap;
      }
    },
    // 编辑
    getStepFiveData() {
      let params;
      if (this.$store.state.creatform.actionType == "edit") {
        params = { id: this.$store.state.creatform.itemDataId };
      } else {
        // 新建
        params = { id: "" };
        this.stepFiveformData.location = "";
        this.stepFiveformData.subject = "";
        this.stepFiveformData.remark = "";
        this.stepFiveformData.bgimage = "";
        this.stepFiveformData.logo = "";
      }
      marketChannel
        .getFormdetail(params)
        .then((res) => {
          if (res.result) {
            // 回显表单的值
            if (this.$store.state.creatform.itemDataId) {
              this.$store.state.creatform.selectTempFlg = res.data.modeltype;
              this.stepFiveformData.location = res.data.location;
              this.stepFiveformData.subject = res.data.subject;
              this.stepFiveformData.remark = JSON.parse(res.data.remark);
              this.stepFiveformData.bgimage = res.data.bgimage;
              this.stepFiveformData.logo = res.data.logo;
              this.center.lng = res.data.longitude;
              this.center.lat = res.data.latitude;
              this.actlocat = res.data.address;
              this.see = false;
              this.objectid = res.data.objectid;
              this.ownerobjectid = res.data.ownerobjectid;
            }
          }
        })
        .catch(() => {
          return false;
        });
    },
    getStepThreeData() {
      let params;
      if (this.$store.state.creatform.objectid === "201200000108754gEHnj") {
        if (this.$store.state.creatform.actionType == "edit") {
          params = { id: this.$store.state.creatform.itemDataId };
        } else {
          params = { id: "" };
        }

        marketChannel
          .getFieldShow(params)
          .then((res) => {
            if (res.result) {
              // for (let i = 0; i < res.data.selectedFields.length; i++) {
              //   for (let j = 0; j < res.data.allFields.length; j++) {
              //     if (res.data.allFields[j].fieldname == res.data.selectedFields[i].fieldname
              //     &&(res.data.allFields[j].objectid === this.$store.state.creatform.objectid||
              //     res.data.allFields[j].objectid == this.$store.state.creatform.ownerobjectid)) {
              //       this.StepThreeData.push({
              //         fieldname:res.data.allFields[j].fieldname,
              //         label:res.data.allFields[j].label,
              //         fieldtype:res.data.allFields[j].fieldtype,
              //         id:res.data.allFields[j].id,
              //         required:res.data.selectedFields[i].required,
              //         objectid: res.data.allFields[j].objectid,
              //         ownerobjectid: res.data.allFields[j].ownerobjectid
              //         })
              //     }
              //   }
              // }
              res.data.selectedFields.forEach((val) => {
                if (
                  val.required === "1" &&
                  (val.objectid === "lead" ||
                    val.objectid === "contact" ||
                    val.objectid === "201200000108754gEHnj") &&
                  val.fieldname === "email"
                ) {
                  this.$set(val, "defaultRequired", true);
                } else {
                  this.$set(val, "defaultRequired", false);
                }
              });
              this.StepThreeData = res.data.selectedFields;
            }
          })
          .catch(() => {
            return false;
          });
      } else {
        if (this.$store.state.creatform.itemDataId) {
          params = {
            id: this.$store.state.creatform.itemDataId,
            objectid: this.$store.state.creatform.objectid,
            ownerobjectid: this.$store.state.creatform.ownerobjectid,
          };
          this.getFormObjectField(params);
        } else {
          // 新建
          params = {
            id: "",
            objectid: this.$store.state.creatform.objectid,
            ownerobjectid: this.$store.state.creatform.ownerobjectid,
          };
          this.getFormObjectField(params);
        }
      }
    },
    getFormObjectField(params) {
      marketChannel
        .getFormObjectField(params)
        .then((res) => {
          if (res.result) {
            // for (let i = 0; i < res.data.selectedFields.length; i++) {
            //   for (let j = 0; j < res.data.allFields.length; j++) {
            //     if (
            //       res.data.allFields[j].fieldname ==
            //       res.data.selectedFields[i].fieldname
            //       &&(res.data.allFields[j].objectid == this.$store.state.creatform.objectid||
            //       res.data.allFields[j].objectid == this.$store.state.creatform.ownerobjectid)
            //     ) {
            //       this.StepThreeData.push({
            //       label:res.data.allFields[j].label,
            //       required:res.data.selectedFields[i].required,
            //       fieldname:res.data.allFields[j].fieldname,
            //       fieldtype:res.data.allFields[j].fieldtype,
            //       id:res.data.allFields[j].id,
            //       defaultRequired:res.data.selectedFields[i].required==='1'?true:false,
            //       objectid: res.data.allFields[j].objectid,
            //       ownerobjectid: res.data.allFields[j].ownerobjectid
            //       })
            //     }
            //   }
            // }
            res.data.selectedFields.forEach((val) => {
              if (
                val.required === "1" &&
                (val.objectid === "lead" ||
                  val.objectid === "contact" ||
                  val.objectid === "201200000108754gEHnj") &&
                val.fieldname === "email"
              ) {
                this.$set(val, "defaultRequired", true);
              } else {
                this.$set(val, "defaultRequired", false);
              }
            });
            this.StepThreeData = res.data.selectedFields;
          }
        })
        .catch(() => {
          return false;
        });
    },
    // 校验
    fiveSubmitForm() {
      let validate = false;
      // 验证
      this.$refs.stepFiveForm.validate((valid) => {
        if (valid) {
          validate = true;
          // alert('submit!');
        } else {
          validate = false;
        }
      });
      return validate;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    bgHandleAvatarSuccess() {
      // this.stepFiveformData.bgimage = URL.createObjectURL(file.raw)
    },
    bgBeforeAvatarUpload(file) {
      // let myfile = this.fileList[0].raw  //这里获取上传的文件对象
      // let formdata = new FormData();
      // formdata.append('uploadFile', myfile);
      // let path = 'banner'
      // this.$server.putFile(formdata, file.name, path).then((res) => {
      //   // this.photoUrl = res.data.data
      //   let imageUrl = ip.other + '/images/' + path + '/' + file.name
      //   let img = new Image()
      //   img.src = imageUrl
      //   var that = this
      //   img.onload = function () {
      //     if (img.height === 360 && img.width === 1200) {

      //     } else {
      //       that.$message.error('该图片尺寸不符，建议上传图片格式为1200px*360px的图片,否则图片将拉伸导致变型！');
      //     }
      //   }
      // }).catch(err => {
      //   this.$message({
      //     message: 'banner图片添加失败',
      //     type: 'error'
      //   });

      // })
      var _this = this;
      //限制图片比例
      // return new Promise(function (resolve, reject) {
      //   var reader = new FileReader();
      //   reader.onload = function (event) {
      //     var txt = event.target.result;
      //     var img = document.createElement("img");
      //     img.src = txt;
      //     img.onload = function () {
      //       if (img.width / img.height !== 2) {
      //         _this.$message.error(
      //           '该图片尺寸不符，建议上传图片格式 宽：高为2:1,否则图片将拉伸导致变型！'
      //         )
      //         return false
      //       } else {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let imgtype = file.name.slice(file.name.lastIndexOf(".") + 1);
      // let that = this
      if (!isLt2M) {
        this.$message({
          message: this.$i18n.t("label.upload.file.size.limit"),
          type: "warning",
        });
        this.$message.error(
          file.name + this.$i18n.t("vue_label_file_over_file_size")
        );
        return false;
      } else if (_this.fileType.indexOf(imgtype) === -1) {
        this.$message.error(
          this.$message.error(
            this.$i18n.t("vue_label_file_wrong_type", { type: imgtype })
          )
        );
        return false;
      } else {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("groupid", "");
        formData.append("libid", "");
        formData.append("parentid", "");
        formData.append("isFromEmail", "");
        CommonObjApi.uploadImg(formData)
          .then((res) => {
            if (res.result && res.data.fileid) {
              _this.stepFiveformData.bgimage = res.data.fileid;
              // if (bgOrLogo === 'bg') {
              //   that.stepFiveformData.bgimage = that.imgSrc(res.data.fileid)
              // } else {
              //   that.stepFiveformData.logo = that.imgSrc(res.data.fileid)
              // }
            }
          })
          .catch(() => {
            return false;
          });
      }

      // }
      //     }
      //   };

      //   reader.readAsDataURL(file);

      // })
    },
    logoHandleAvatarSuccess() {
      // this.logo = URL.createObjectURL(file.raw)
    },
    logoBeforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let imgtype = file.name.slice(file.name.lastIndexOf(".") + 1);
      let that = this;
      if (!isLt2M) {
        this.$message({
          message: this.$i18n.t("label.upload.file.size.limit"),
          type: "warning",
        });
        // this.$message.error(file.name + this.$i18n.t('vue_label_file_over_file_size'))
        return false;
      } else if (this.fileType.indexOf(imgtype) === -1) {
        this.$message.error(
          this.$message.error(
            this.$i18n.t("vue_label_file_wrong_type", { type: imgtype })
          )
        );
        return false;
      } else {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        formData.append("groupid", "");
        formData.append("libid", "");
        formData.append("parentid", "");
        formData.append("isFromEmail", "");
        CommonObjApi.uploadImg(formData)
          .then((res) => {
            if (res.result && res.data.fileid) {
              that.stepFiveformData.logo = res.data.fileid;
            }
          })
          .catch(() => {
            return false;
          });
      }
    },
    //删除图片
    imgDelete(val) {
      if (val === "logo") {
        this.stepFiveformData.logo = "";
      } else {
        this.stepFiveformData.bgimage = "";
      }
    },
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-input__suffix {
  top: -4px !important;
}
::v-deep .selectImg .baglog .smallrema .selected input {
  width: 96% !important;
}
::v-deep .el-form-item__label {
  line-height: 26px;
}
.bm-view {
  margin: 10px auto;
  width: 100%;
  height: 200px;
}
.bm_search {
  z-index: 99 !important;
  max-height: 400px !important;
  width: 100%;
  background: #ddd;
  display: inline-block;
  position: relative;
  left: 105%;
  top: -100%;
}
.map-Box {
  border: 1px solid #8c939d;
  height: 80px;
  margin: 10px auto;
  border: 1px solid #8c939d;
}
.selectImg {
  width: 300px;
  height: 470px;
  overflow: hidden;
  background: #f4f8fa;
  .baglog {
    width: 80%;
    margin-left: 10%;
    margin-top: 25px;
    margin-bottom: 25px;
    background: #fff;
    overflow: hidden;
    .smallrema {
      width: 90%;
      margin-left: 5%;
      height: 400px;
      margin-top: 10px;
      margin-bottom: 10px;
      overflow: auto;
      .smallog {
        width: 100%;
        height: 40px;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .samllgeneralMode {
        width: 100%;
        margin-top: 5px;
        background: palegoldenrod;
        img {
          width: 100%;
          margin-top: 5px;
          margin-bottom: 5px;
          object-fit: contain;
        }
      }
      h1 {
        width: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        white-space: nowrap;
      }
      .smallp {
        text-align: center;
        width: 100%;
        font-size: 12px;
        ::v-deep .el-textarea__inner {
          border: none;
          resize: none;
          padding: 0;
        }

        // transform: scale(0.7);
        zoom: 0.7;
      }
      .ziduan {
        width: 100%;
        height: 150px;
        // background: #ddd;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .selected {
        width: 100%;
        margin-top: 10px;
        p {
          margin: 0;
          font-size: 12px;
          zoom: 0.8;
          span {
            color: red;
            // padding-right: 5px;
          }
        }
        input {
          width: 100%;
          outline: none;
          border: 1px solid #cbd6e2;
          height: 20px;
          background: #f5f8fa;
        }
      }
      .baiduapp {
        width: 100%;
        .baiduview {
          margin: 10px auto;
          width: 100%;
          height: 100px;
        }
        p {
          zoom: 0.7;
        }
      }
      .baidusub {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        button {
          border: none;
          outline: none;
          color: #fff;
          background: #2d6cfc;
          border-radius: 3px;
          margin-bottom: 10px;
          padding: 2px 5px;
          zoom: 0.9;
        }
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 4px;
}
::v-deep .el-form-item__content {
  line-height: 20px;

  font-size: 14px;
  width: 75%;
}

.base-form {
  font-size: 12px;
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 50%;
  font-size: 12px;
}
::v-deep .el-radio {
  color: #080707;
  cursor: pointer;
}
::v-deep .el-input__inner {
  height: 30px;
  font-size: 12px;
}
::v-deep .el-textarea__inner {
  height: 100px;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
::v-deep .avatar-uploader-iconsecond {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
::v-deep .avatar {
  width: 300px;
  display: block;
}
.pic {
  width: 323px;
  height: 469px;
  img {
    width: 100%;
    height: 100%;
  }
}
.formStyle {
  width: 100%;
  font-size: 14px;
  color: #080707;
  text-align: center;
  margin-bottom: 1em;
}
.clearfix {
  width: 400px;
}
.smallrema::-webkit-scrollbar {
  width: 0px;
}
.del_btn,
.del_btn:hover {
  height: 28px;
  margin: 10px 0;
  padding: 7px 15px;
  background: #2d6cfc;
  font-size: 12px;
}
</style>
