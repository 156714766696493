<!-- 列表上方按钮 -->
<template>
  <div class="viewButtons">
    <!-- 视图弹窗 -->
    <!--        <div style="text-align: center">-->
    <!--            <i-->
    <!--                    v-if="loading"-->
    <!--                    class="el-icon-loading"-->
    <!--                    style="font-size: 20px; margin-top: 200px"-->
    <!--            >-->
    <!--            </i>-->
    <!--        </div>-->
    <div class="leftvalue">
      <el-form
        ref="objForms"
        :model="stepTwoFormData"
        :label-width="formLabelWidth"
        label-position="left"
        size="small"
      >
        <el-col :span="24">
          <div class="screen_s" :span="24">
            <el-form-item>
              <!-- <span class="btn_s"></span> -->
              <div class="select_one fieldTextStyle" style="margin-left: 28px">
                {{ $t("setup.layout.label.selectedfield") }}
              </div>
              <p class="equalP"></p>
              <div class="select_two fieldTextStyle">
                <div style="margin-left: 180px">
                  {{ $t("label.tabpage.value") }}
                </div>
              </div>
              <div style="width: 100px"></div>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="24">
          <div
            :span="24"
            class="screen_s"
            v-for="(ev, index) in stepTwoFormData.leadDefaultValues"
            :key="index"
          >
            <el-form-item>
              <span>{{ index + 1 }}、</span>
              <el-select
                v-model="ev.fieldid"
                :placeholder="$t('label.selectfield')"
                class="select_one sel_style"
                filterable
                clearable
                @change="selectChange($event, index, ev)"
                @focus="selectFocus(index)"
                @clear="clearField(ev)"
              >
                <el-option
                  v-for="item in selectFieldList"
                  :key="item.datafieldRef"
                  :label="item.labelName"
                  :value="item.datafieldRef"
                  @click.native="optionChange(item, index)"
                ></el-option>
              </el-select>
              <p class="equalP">{{ $t("label.tabpage.equalz") }}</p>
              <div class="select_two sel_style_box">
                <el-input
                  :placeholder="$t('label.please.fill.out')"
                  v-model="ev.dvalue"
                  v-if="
                    ev.schemefieldType !== 'L' &&
                    ev.schemefieldType !== 'ct' &&
                    ev.schemefieldType !== 'F' &&
                    ev.schemefieldType !== 'D'
                  "
                >
                </el-input>
                <el-select
                  v-else-if="ev.schemefieldType == 'L'"
                  v-model="ev.dvalue"
                  :placeholder="$t('label.selectfield')"
                  class="select_one sel_style"
                  filterable
                  clearable
                  @focus="selectListAll()"
                >
                  <el-option
                    v-for="item in selectList"
                    :key="item.codekey"
                    :label="item.codekey"
                    :value="item.codevalue"
                    @click.native="optionselect(item, index)"
                  ></el-option>
                </el-select>
                <el-select
                  v-else-if="ev.schemefieldType == 'ct'"
                  v-model="ev.dvalue"
                  :placeholder="$t('label.selectfield')"
                  class="select_one sel_style"
                  filterable
                  clearable
                  @focus="selectListAll(ev.schemefieldType)"
                >
                  <el-option
                    v-for="item in currentList"
                    :key="item.currencyname"
                    :label="item.currencyname"
                    :value="item.currencycode"
                    @click.native="optionselect(item, index)"
                  ></el-option>
                </el-select>
                <!-- <TimePerson
              v-model="ev.dvalue"
              v-else-if="
              ev.schemefieldType === 'F' || ev.schemefieldType === 'D'
              "
              :editValue="ev.dvalue"
              :inputType="inputType"
              @getChartsByCondition="getChartsByCondition"
             >  
            </TimePerson> -->
                <el-date-picker
                  v-model="ev.dvalue"
                  v-else-if="
                    ev.schemefieldType === 'F' || ev.schemefieldType === 'D'
                  "
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
              <div style="width: 100px">
                <span
                  class="btn_s"
                  @click="dels(index)"
                  v-if="stepTwoFormData.leadDefaultValues.length !== 1"
                  >-</span
                >
                <span
                  class="btn_s"
                  @click="adds"
                  v-if="stepTwoFormData.leadDefaultValues.length - 1 === index"
                  >+</span
                >
              </div>
            </el-form-item>
          </div>
        </el-col>
      </el-form>
    </div>
    <div class="selectImg">
      <div class="baglog">
        <div class="smallrema">
          <div v-if="baidudataD.logo" class="smallog">
            <img :src="imgSrc(baidudataD.logo)" alt="" />
          </div>
          <div
            v-if="
              baidudataD.bgimage &&
              $store.state.creatform.selectTempFlg == 'generalMode'
            "
            class="samllgeneralMode"
          >
            <img :src="imgSrc(baidudataD.bgimage)" alt="" />
          </div>
          <h1 v-if="baidudataD.subject">
            {{ baidudataD.subject }}
          </h1>
          <el-input
            type="textarea"
            class="smallp"
            v-if="baidudataD.remark"
            :autosize="{ minRows: 0 }"
            v-model="baidudataD.remark"
            readonly
          ></el-input>
          <div
            v-if="baidudataD.subject && viewSelectedFieldList.length < 1"
            class="ziduan"
          >
            <div v-for="sel in arr" :key="sel.fieldname" class="selected">
              <p>
                {{ sel.label }}
                <span v-if="sel.required === '1'">*</span>
              </p>
              <input type="text" disabled />
            </div>
          </div>
          <div
            v-if="baidudataD.subject && viewSelectedFieldList.length > 0"
            class="selectlists"
          >
            <div
              v-for="sel in viewSelectedFieldList"
              :key="sel.label"
              class="selected"
            >
              <p>{{ sel.label }}<span v-if="sel.required == 1">*</span></p>
              <input type="text" disabled />
            </div>
            <!-- <div
              v-for="select in viewNoSelectedFieldList"
              :key="select.label"
              class="selected"
            >
              <p>{{ select.label }}</p>
              <input type="text" />
            </div> -->
          </div>
          <div
            class="baiduapp"
            v-if="
              $store.state.creatform.selectTempFlg == 'positioningModel' &&
              baidudataD.location
            "
          >
            <baidu-map
              v-if="baidushow"
              class="baiduview"
              :center="baidudataD.center"
              zoom="19"
              :scroll-wheel-zoom="true"
            >
              <bm-marker :position="baidudataD.center"> </bm-marker>
            </baidu-map>
            <p v-if="baidudataD.actlocat">
              {{ $t("AD") }}：{{ baidudataD.actlocat }}
            </p>
          </div>
          <div class="baidusub">
            <button v-if="baidudataD.subject">{{ $t("label.submit") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as marketChannel from "../../api"; //api
import Vue from "vue";
import BaiduMap from "vue-baidu-map"; //引入百度地图组件库
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "6XPhZVfaUKeRAU9Q4X9zRwtiNGouGdzl",
});
export default {
  name: "stepTwo",
  props: {
    baidudata: {
      type: Object,
      default: () => {},
    },
    viewSelectedFieldList: {
      type: Array,
      default: () => [],
    },
  },

  computed: {},
  data() {
    return {
      baidudataD: this.baidudata,
      arr: [
        {
          label: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
        {
          label: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
        {
          label: this.$i18n.t("label.tabpage.field"),
          value: "",
        },
      ],
      loading: true,
      formLabelWidth: "118px",
      dataId: 1,
      //初始数据
      initData: {
        fieldid: "", // select 绑定的值
        dvalue: "", // input绑定的值
        objectid: "",
        schemefieldType: "", //输入框类型
      },
      stepTwoFormData: {
        leadDefaultValues: [
          {
            fieldid: "", // select 绑定的值
            dvalue: "", // input绑定的值
            objectid: "",
            schemefieldType: "", //输入框类型
          },
          {
            fieldid: "", // select 绑定的值
            dvalue: "", // input绑定的值
            objectid: "",
            schemefieldType: "", //输入框类型
          },
          {
            fieldid: "", // select 绑定的值
            dvalue: "", // input绑定的值
            objectid: "",
            schemefieldType: "", //输入框类型
          },
          {
            fieldid: "", // select 绑定的值
            dvalue: "", // input绑定的值
            objectid: "",
            schemefieldType: "", //输入框类型
          },
        ],
      },
      selectFieldList: [], // 下拉框的数据 从后端获取
      projectTasksObjectApi: "",
      // objForm: {
      //   label: '',
      //   filter: '', //高级筛选值
      //   screeRadio: '0',
      //   visibRadio: 'fsharenone',
      //   createbyidccname: '', //创建人
      //   createdate: '', //创建时间
      //   lastmodifybyidccname: '', //最后修改人
      //   lastmodifydate: '', //最后修改时间
      //   list: [
      //     {
      //       fieldId: '',
      //       inputField: '',
      //       op: '',
      //       val: {
      //         value: '',
      //       },
      //       type: 1,
      //       id: 1,
      //       inputType: 'input',
      //       optionst_two: [],
      //       objid: '',
      //     },
      //     {
      //       fieldId: '',
      //       inputField: '',
      //       op: '',
      //       val: {
      //         value: '',
      //       },
      //       type: 1,
      //       id: 1,
      //       inputType: 'input',
      //       optionst_two: [],
      //       objid: '',
      //     },
      //     {
      //       fieldId: '',
      //       inputField: '',
      //       op: '',
      //       val: {
      //         value: '',
      //       },
      //       type: 1,
      //       id: 1,
      //       inputType: 'input',
      //       optionst_two: [],
      //       objid: '',
      //     },
      //     {
      //       fieldId: '',
      //       inputField: '',
      //       op: '',
      //       val: {
      //         value: '',
      //       },
      //       type: 1,
      //       id: 1,
      //       inputType: 'input',
      //       optionst_two: [],
      //       objid: '',
      //     },
      //   ],
      // },
      token: this.$CCDK.CCToken.getToken(),
      baidushow: false,
      schemefieldType: "", //判断筛选条件后显示输入框还是下拉框
      selectList: [], //筛选框下拉值
      selectId: "", //选择id
      currentList: [], //币种列表
      inputType: "date",
      editValue: "",
      Listindex: "",
    };
  },
  created() {
    // this.getStepTwoData();
  },
  methods: {
    getChartsByCondition(val) {
      this.editValue = val;
      this.stepTwoFormData.leadDefaultValues[this.Listindex].dvalue =
        this.editValue;
      this.editValue = "";
    },
    //获取选项列表值
    selectListAll(type) {
      if (type == "ct") {
        this.getCurrencyInfo();
      } else {
        this.getSelectValue();
      }
    },
    // 获取货币列表接口
    getCurrencyInfo() {
      marketChannel.getCurrencyInfo().then((res) => {
        this.currentList = res.data;
      });
    },
    //获取下拉列表接口
    getSelectValue() {
      marketChannel.getSelectValue({ fieldId: this.selectId }).then((res) => {
        this.selectList = res.data;
      });
    },
    init(formlist) {
      this.baidushow = true;
      this.getStepTwoData(formlist);
    },
    //获取图片
    imgSrc(id) {
      return `${this.$cookies.get(
        "domainName"
      )}/querysome.action?m=viewImg&fileid=${id}&binding=${this.token}`;
    },
    // 获取步骤二页面的数据
    getStepTwoData(formlist) {
      //市场活动成员才调用这个接口
      let params;
      if (this.$store.state.creatform.actionType == "edit") {
        params = { id: this.$store.state.creatform.itemDataId };
      } else {
        // 新建
        params = {};
      }
      params.objectid = this.$store.state.creatform.objectid;
      params.ownerobjectid = this.$store.state.creatform.ownerobjectid;
      params.fields = JSON.stringify(formlist);
      marketChannel
        .getFieldDefault(params)
        .then((res) => {
          if (res.result) {
            if (
              this.$store.state.creatform.objectid === "201200000108754gEHnj"
            ) {
              res.data.fieldlist.forEach((val) => {
                this.$set(
                  val,
                  "objectid",
                  this.$store.state.creatform.objectid
                );
              });
              this.selectFieldList = res.data.fieldlist;
            }
            if (this.$store.state.creatform.actionType == "edit") {
              // 回显表单的值  将空的放在数组后面

              // res.data.leaddvalue.forEach((item, index) => {
              // if ((item.fieldid == "" && item.dvalue == "") || (item.fieldid == "null" && item.dvalue == "null")) {
              //   let obj = item;
              //   res.data.leaddvalue.splice(index, 1);// 第i位删除1项
              //   res.data.leaddvalue.push(obj)
              // } else {
              //   let obj = item;
              //   res.data.leaddvalue.splice(index, 1);// 第i位删除1项
              //   res.data.leaddvalue.unshift(obj)
              // }
              // })
              res.data.leaddvalue.map((item) => {
                if (item.schemefieldType == "ct") {
                  this.getCurrencyInfo();
                } else if (item.schemefieldType == "L") {
                  this.selectId = item.fieldid;
                  this.getSelectValue();
                }
              });
              if (res.data.leaddvalue.length >= 4) {
                for (var i = 0; i < res.data.leaddvalue.length; i++) {
                  this.stepTwoFormData.leadDefaultValues[i].fieldid =
                    res.data.leaddvalue[i].fieldname;
                  this.stepTwoFormData.leadDefaultValues[i].dvalue =
                    res.data.leaddvalue[i].dvalue;
                  this.stepTwoFormData.leadDefaultValues[i].schemefieldType =
                    res.data.leaddvalue[i].schemefieldType;
                  // this.stepTwoFormData.leadDefaultValues = res.data.leaddvalue
                }
              } else {
                // 如果长度小于4那么补齐
                if (res.data.leaddvalue.length < 4) {
                  for (let i = 0; i < res.data.leaddvalue.length; i++) {
                    this.stepTwoFormData.leadDefaultValues[i].fieldid =
                      res.data.leaddvalue[i].fieldname;
                    this.stepTwoFormData.leadDefaultValues[i].dvalue =
                      res.data.leaddvalue[i].dvalue;
                    this.stepTwoFormData.leadDefaultValues[i].schemefieldType =
                      res.data.leaddvalue[i].schemefieldType;
                  }
                  for (let i = res.data.leaddvalue.length; i < 4; i++) {
                    res.data.leaddvalue.push({
                      fieldid: "", // select 绑定的值
                      dvalue: "", // input绑定的值
                    });
                  }
                }
                // this.stepTwoFormData.leadDefaultValues = res.data.leaddvalue
              }
            } else {
              // this.stepTwoFormData.leadDefaultValues = this.stepTwoFormData.leadDefaultValues
            }
          }
        })
        .catch(() => {
          return false;
        });
    },
    //初始化数据
    // init() {
    //   this.objForm = {
    //     label: '',
    //     filter: '',
    //     screeRadio: '0',
    //     visibRadio: 'fsharenone',
    //     list: [
    //       {
    //         fieldId: '',
    //         op: '',
    //         val: {
    //           value: '',
    //         },
    //         type: 1,
    //         id: 1,
    //         inputType: 'input',
    //         optionst_two: [],
    //       },
    //     ],
    //   }
    // },
    //新增
    adds() {
      if (this.dataId < 10) {
        this.dataId += 1;
        this.stepTwoFormData.leadDefaultValues.push({
          ...this.initData,
          id: this.dataId,
        });
      } else {
        return;
      }
    },
    //删除
    dels(id) {
      this.dataId = id;
      this.stepTwoFormData.leadDefaultValues.splice(id, 1);
    },
    selectFocus(index) {
      this.Listindex = index;
    },
    clearField(item) {
      item.fieldid = "";
      item.dvalue = "";
      item.objectid = "";
      item.schemefieldType = "";
    },
    //根据筛选字段判断值
    selectChange(val, index, item) {
      item.dvalue = "";
      if (val) {
        let leadarr = this.stepTwoFormData.leadDefaultValues;
        let num = 0;
        for (let i = 0; i < leadarr.length; i++) {
          if (leadarr[i].fieldid == val) {
            num++;
            if (num > 1) {
              this.$message({
                message: this.$i18n.t("label.field_selected"),
                type: "warning",
              });
              this.stepTwoFormData.leadDefaultValues[i].fieldid = "";
              return;
            }
          }
        }
      }
    },
    optionChange(item) {
      // 设置静默字段objectid
      this.stepTwoFormData.leadDefaultValues[this.Listindex].objectid =
        item.objectid;
      this.selectId = item.id;
      this.stepTwoFormData.leadDefaultValues[this.Listindex].schemefieldType =
        item.fieldtype;
    },
  },
  // watch: {
  //   // 获取路由
  //   $route: {
  //     handler: function (route) {
  //       // 获取到项目任务页面对象api
  //       this.projectTasksObjectApi = route.params.objectApi
  //     },
  //     immediate: true,
  //   },
  // },
};
</script>
<style lang="scss" scoped>
::v-deep .groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  margin-bottom: 1em;
  background: #f0f0f0 !important;
}
::v-deep .selectImg .baglog .smallrema .selected input {
  width: 96% !important;
}
.equalP {
  width: 70px;
  text-align: center;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #080707;
  margin-bottom: 0;
}

.el-button-group {
  /*display: inline-block;*/
  vertical-align: baseline;
}
::v-deep .el-form-item--small .el-form-item__content,
.el-form-item--small .el-form-item__label {
  line-height: 30px;
}
.viewButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 180px 0 80px;
  // width: 90%;
  // margin-left: 5%;
  .leftvalue {
    width: 700px;
  }
  ::v-deep .editableCell {
    height: 30px;
  }

  .el-button-group {
    vertical-align: baseline;
  }
}
.screen_s > div {
  margin-bottom: 0 !important;
}
::v-deep .sel_style,
::v-deep .sel_style_box {
  width: 250px;
  .el-input__inner {
    height: 30px;
  }
}
::v-deep .screen_s .editableCell {
  .el-select__tags {
    flex-wrap: unset;
    overflow-x: overlay;
    top: 53%;
    height: 30px;
    overflow-y: hidden;
  }

  .el-input .el-input__inner {
    width: 339px;
    height: 30px !important;
  }

  .el-tag {
    margin: 0 5px !important;
  }
}
.screen_s ::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
  line-height: 30px;
  margin-left: 0 !important;
}

.el-form-item {
  margin-bottom: 0;
}
::v-deep .el-row {
  // margin-bottom: 40px;
  width: 100%;
}
.select_one {
  margin: 6px;
}

.select_two {
  // width: 339px;
  margin: 6px 12px 6px 6px;
  // height: 50px;
}

.btn_s {
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
  // background: #2D6CFC;
  // width:40px;
  // height:40px;
  // border-radius:50%;
  // color:#fff;
}
.fieldTextStyle {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}
.selectImg {
  width: 300px;
  min-height: 400px;
  background: #f4f8fa;
  .baglog {
    width: 80%;
    margin-left: 10%;
    margin-top: 25px;
    margin-bottom: 25px;
    background: #fff;
    overflow: hidden;
    .smallrema {
      width: 90%;
      margin-left: 5%;
      height: 400px;
      margin-top: 10px;
      margin-bottom: 10px;
      overflow: auto;
      .smallog {
        width: 100%;
        height: 40px;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
      .samllgeneralMode {
        width: 100%;
        margin-top: 5px;
        background: palegoldenrod;
        img {
          width: 100%;
          margin-bottom: 5px;
          margin-top: 5px;
          object-fit: contain;
        }
      }
      h1 {
        width: 100%;
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis; //文本溢出显示省略号
        white-space: nowrap;
      }
      .smallp {
        width: 100%;
        font-size: 12px;
        zoom: 0.7;
        ::v-deep .el-textarea__inner {
          border: none;
          resize: none;
          padding: 0;
        }

        // transform: scale(0.7);
      }
      .ziduan {
        width: 100%;
        height: 150px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .selected {
        width: 100%;
        margin-top: 10px;
        p {
          margin: 0;
          font-size: 12px;
          zoom: 0.8;
          span {
            color: red;
            padding-right: 5px;
          }
        }
        input {
          width: 100%;
          outline: none;
          border: 1px solid #cbd6e2;
          height: 20px;
          background: #f5f8fa;
        }
      }
      .baiduapp {
        width: 100%;
        .baiduview {
          margin: 10px auto;
          width: 100%;
          height: 100px;
        }
        p {
          zoom: 0.7;
        }
      }
      .baidusub {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        button {
          border: none;
          outline: none;
          color: #fff;
          background: #2d6cfc;
          border-radius: 3px;
          margin-bottom: 10px;
          padding: 2px 5px;
          zoom: 0.8;
        }
      }
    }
  }
}
.smallrema::-webkit-scrollbar {
  width: 0px;
}
</style>
